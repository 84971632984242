
import { Link } from "react-router-dom";
import back_icon from "../../../assets/img/section/section-back-icon.png";
import service_icon_1 from "../../../assets/img/services/service1.png";
import service_icon_2 from "../../../assets/img/services/service2.png";
import service_icon_3 from "../../../assets/img/services/service3.png";
import service_icon_4 from "../../../assets/img/services/service4.png";
import service_icon_5 from "../../../assets/img/services/service5.png";
import service_icon_6 from "../../../assets/img/services/service6.png";
import title_line from "../../../assets/img/shape/section-title-line.png";
import { APP_ROUTES } from "../../../utils/constant";


interface service_content_type {
  sub_title: string;
  title: string;
  service_data: {
    id: number;
    img: string;
    title: string;
    sm_des: string;
    url: string;
  }[];
}
const service_content: service_content_type = {
  sub_title: "Departments",
  title: "Managed Your Heathcare Services",
  service_data: [
    {
      id: 1,
      img: service_icon_1,
      title: "Hair Loss",
      sm_des: "Natrum Muriaticum is an interesting example of homeopathic medicine prepared from a common food substance, the common salt, sodium chloride.",
      url: APP_ROUTES.HAIR_LOSS
    },
    {
      id: 2,
      img: service_icon_2,
      title: "Thyroid",
      sm_des: "This is an interesting example of homeopathic medicine prepared from a common food substance, the common salt, sodium chloride. As a rule, the common.",
      url: APP_ROUTES.DISEASE_AUTISM
    },
    {
      id: 3,
      img: service_icon_3,
      title: "Asthma",
      sm_des: "Difficulty in breathing is referred as asthma. This always accompanies with a restlessness and craving for sweat. It can be of respiratory in origin.",
      url: APP_ROUTES.DISEASE_ASTHMA
    },
    {
      id: 4,
      img: service_icon_4,
      title: "Weight Loose",
      sm_des: "Weight Loss is the amount of body weight we lose by burning calories, as compared to the body weight we gain by intake of extra calories by bad eating habits.",
      url: APP_ROUTES.DISEASE_GROWTH
    },
    {
      id: 5,
      img: service_icon_5,
      title: "Cervical Spondylitis",
      sm_des: "This is one of the best Homeopathic remedies for the treatment of cervical spondylitis. This remedy is prepared by the potentization of the plant comm.",
      url: APP_ROUTES.DISEASE_JOINTPAIN
    },
    {
      id: 6,
      img: service_icon_6,
      title: "Migraine",
      sm_des: "Migraine is a condition caused due to specific neuro chemical changes in the brain characterized by recurrent headaches usually on one half of the head.",
      url: APP_ROUTES.DISEASE_DEPRESSION
    },
  ],
}
const { sub_title, title, service_data } = service_content

const ServicesAreaHome = () => {
  return (
    <>
      <section className="servcies-area gray-bg pt-115 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div className="section-title text-center pos-rel mb-75">
                <div className="section-icon">
                  <img className="section-back-icon" src={back_icon} alt="theme-pure" />
                </div>
                <div className="section-text pos-rel">
                  <h5>{sub_title}</h5>
                  <h1>{title}</h1>
                </div>
                <div className="section-line pos-rel">
                  <img src={title_line} alt="theme-pure" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {service_data.map((item, i) =>
              <div key={i} className="col-xl-4 col-lg-6 col-md-6">
                <div className="service-box text-center mb-30">
                  <div className="service-thumb">
                    <img src={item.img} alt="theme-pure" />
                  </div>
                  <div className="service-content">
                    <h3><Link to="#">{item.title}</Link></h3>
                    <p>{item.sm_des}</p>
                    <Link className="service-link" to={item.url}>Read More</Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesAreaHome;
import React from "react";
import SocialLinks from "../common/SocialLinks";


interface Props {
    avatarImg: string;
    doctorName: string,
    diseaseCauseDesc: string;
}

const DiseaseAuthor: React.FC<Props> = ({ avatarImg, doctorName, diseaseCauseDesc }) => {

    return (
        <>
            <div className="author mt-40 mb-40">
                <div className="author-img text-center">
                    <img src={require(`../../assets/img/doctor/${avatarImg}`)} alt="title" width={120} height={120} style={{ borderRadius: "50%" }} />
                </div>
                <div className="author-text text-center">
                    <h3>Dr. {doctorName}</h3>
                    <div className="author-icon">
                        <SocialLinks />
                    </div>
                    <p>{diseaseCauseDesc} </p>
                </div>
            </div>
        </>
    )
};

export default DiseaseAuthor;

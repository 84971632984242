
import backIcon from "../../../assets/img/section/section-back-icon.png";
import titleLine from "../../../assets/img/shape/section-title-line.png";

import { Link } from "react-router-dom";
import blog_update_1 from "../../../assets/img/home/update1.jpg";
import blog_update_2 from "../../../assets/img/home/update2.jpg";
import { SH_DOCTOR } from "../../../utils/constant";

// Data type
interface LatestContentDataType {
  sub_title: string;
  title: string;
  latest_news_data: {
    id: number;
    img: string;
    tag_1: string;
    tag_2: string;
    title: string;
    sm_des: string;
  }[];
  singl_news: {
    id: number;
    title: string;
    time: string;
    comments: number;
    url: string;
  }[];
}
// latest content 
const latest_content: LatestContentDataType = {
  sub_title: "News",
  title: "Get Every Single Updates Here.",
  latest_news_data: [
    {
      id: 1,
      img: blog_update_1,
      tag_1: "Dengue",
      tag_2: "Malaria",
      title: "Dengue Awareness Program by Shivam Health Care research ",
      sm_des: "Shivam Health Care Research Center conducted an awareness program highlighted on Dengue and Malaria. The basic objective of this campaign was to increase awareness in an effective way among the general public. The campaign was initiated with surveys and questionnaires. Approximately two hundred people had participated in this program. The results were recorded for further use.",
    },
    {
      id: 2,
      img: blog_update_2,
      tag_1: "Infertility",
      tag_2: "PCOS/PCOD",
      title: "Women Health Check-up & Awareness Camp.",
      sm_des: "Free Women Health Camps are organised under the dynamic guidance of " + SH_DOCTOR.DR_NAMRATA + " from time to time. It aims to equip them with knowledge about their personal health, give them better access to health facilities and create awareness about hygienic living along with early detection and prevention, and provide them with the necessary medication to cure their ailments.",
    },
  ],

  singl_news: [
    {
      id: 1,
      title: "Unconrolled diabetes is dangerous, can be controlled better with Homeopathy.",
      time: "20 November 2023",
      comments: 102,
      url: "https://www.facebook.com/photo?fbid=342642191685200&set=a.149219157694172"
    },
    {
      id: 2,
      title: "Diabetes treatement: Dr. " + SH_DOCTOR.DR_ARVIND,
      time: "17 November 2023",
      comments: 33,
      url: "https://www.facebook.com/photo?fbid=340622078553878&set=a.149219157694172"
    },
    {
      id: 3,
      title: "Diet and daily routine : Dr. " + SH_DOCTOR.DR_NAMRATA,
      time: "16 November 2023",
      comments: 19,
      url: "https://www.facebook.com/photo?fbid=340600581889361&set=a.149219147694173"
    },
    {
      id: 4,
      title: "Organised god Dhanvantari worship",
      time: "13 November 2023",
      comments: 44,
      url: "https://www.facebook.com/photo/?fbid=338938672055552&set=a.149219157694172"
    },

  ]
}
const { sub_title, title, latest_news_data, singl_news } = latest_content

type DataType = {
  style?: any;
}
const LatestNewsAreaHomeOne = ({ style }: DataType) => {
  return (
    <>
      <section className="latest-news-area pt-115 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7">
              <div className="section-title pos-rel mb-75">
                <div className="section-icon">
                  <img className="section-back-icon back-icon-left" src={backIcon} alt="theme-pure" />
                </div>
                <div className="section-text pos-rel">
                  <h5>{sub_title}</h5>
                  <h1>{title}</h1>
                </div>
                <div className="section-line pos-rel">
                  <img src={titleLine} alt="theme-pure" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5 d-none d-lg-block">
              <div className="section-button text-end pt-80">
                <Link data-animation="fadeInLeft" data-delay=".6s" to="/blog" className="btn btn-icon ml-0">
                  <span>+</span>our blog
                </Link>
              </div>
            </div>
          </div>




          <div className="row">
            {latest_news_data.map((item, i) =>
              <div key={i} className="col-xl-4 col-lg-6 col-md-6">
                <div className="latest-news-box mb-30">
                  <div className=" mb-35">
                    <img src={item.img} style={{ width: "100%" }} height={300} alt="theme-pure" />
                  </div>
                  <div className="latest-news-content">
                    <div className="news-meta mb-10">
                      <span><Link to="#" className="news-tag">{item.tag_1},</Link></span>
                      <span><Link to="#" className="news-tag">{item.tag_2}</Link></span>
                    </div>
                    <h3><Link to="/news-details">{item.title}</Link></h3>
                    <p>{item.sm_des}</p>
                  </div>
                </div>
              </div>
            )}
            <div className="col-xl-4 col-lg-12 col-md-12">
              <div className="recent-news-list mb-120">
                {singl_news.map((news, index) =>
                  <div key={index} className={`latest-news-content singl-news  ${index === 0 || index === 1 || index === 2 ? "news-border-bottom" : ""}`}>
                    <h3><Link to={news.url}>{news.title}</Link></h3>
                    <span className="meta-date"><i className="far fa-calendar"></i>{news.time}</span>
                    <span className="meta-date"><Link to="#"><i className="far fa-comments"></i>{news.comments} Comments</Link></span>
                  </div>
                )}
              </div>
              {!style &&
                <div className="mk-call-btn f-right mb-30">
                  <Link data-animation="fadeInLeft" data-delay=".6s" to="/contact" className="btn btn-icon btn-icon-green ml-0">
                    <span><i className="fas fa-phone"></i></span>make call
                  </Link>
                </div>
              }
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default LatestNewsAreaHomeOne;
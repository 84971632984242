
import childHealth from "../assets/img/disease-icon/child-health.png";
import depression from "../assets/img/disease-icon/depression.png";
import hairfall from "../assets/img/disease-icon/hairfall.png";
import migraine from "../assets/img/disease-icon/migraine.png";
import pcos from "../assets/img/disease-icon/pcos.png";
import psoriasis from "../assets/img/disease-icon/psoriasis.png";

import bloodpressure from "../assets/img/disease-icon/bloodpressure.png";
import diabetes from "../assets/img/disease-icon/diabetes.png";
import heartdisease from "../assets/img/disease-icon/heartdisease.png";
import infertility from "../assets/img/disease-icon/infertility.png";
import respiratory from "../assets/img/disease-icon/respiratory.png";
import thyroid from "../assets/img/disease-icon/thyroid.png";
import vitiligo from "../assets/img/disease-icon/vitiligo.png";



interface TreatmentDataType {
  id: number;
  img: string;
  name: string;
}

const treatment_data: TreatmentDataType[] = [

  {
    id: 1,
    img: childHealth,
    name: "Child Care",
  },
  {
    id: 2,
    img: migraine,
    name: "Diabetes",
  },
  // home 03
  {
    id: 3,
    img: psoriasis,
    name: "Psoriasis",
  },
  {
    id: 4,
    img: pcos,
    name: "PCOS Treatment",
  },
  {
    id: 5,
    img: vitiligo,
    name: "Vitiligo",
  },
  {
    id: 8,
    img: pcos,
    name: "Infertility",
  },
  {
    id: 6,
    img: thyroid,
    name: "Thyroid",
  },
  {
    id: 7,
    img: hairfall,
    name: "Hairfall",
  },

  // home 04
  {
    id: 9,
    img: depression,
    name: "Blood Pressure",
  },
  {
    id: 10,
    img: migraine,
    name: "Sexual Disfunction",
  },
  {
    id: 11,
    img: respiratory,
    name: "Heart Disease",
  },
  {
    id: 12,
    img: psoriasis,
    name: "Gathiya Bai",
  },
  {
    id: 13,
    img: bloodpressure,
    name: "Blood Pressure",
  },
  {
    id: 14,
    img: diabetes,
    name: "Diabetes",
  }, {
    id: 15,
    img: heartdisease,
    name: "Heart Care",
  },
  {
    id: 16,
    img: infertility,
    name: "Infertility",
  },

]
export default treatment_data



// import { BrowserRouter as Router } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import React from "react";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./auth/RequireAuth";
import AboutHomeopathy from "./pages/about-homeopathy/AboutHomeopathy";
import About from "./pages/about/About";
import AppointmentListPage from "./pages/admin/AppointmentListPage";
import AppointmentPage from "./pages/appointment/AppointmentPage";
import ArvindSrivastav from "./pages/ArvindSrivastav";
import AssuredTreatment from "./pages/assured-treatment/AssuredTreatment";
import AsthmaPage from "./pages/asthma/AsthmaPage";
import AutismPage from "./pages/autism/AutismPage";
import BronchitisPage from "./pages/bronchitis/BronchitisPage";
import CheckoutPage from "./pages/checkout/CheckoutPage";
import ContactPage from "./pages/contact/ContactPage";
import DeepmalaSrivastav from "./pages/DeepmalaSrivastav";
import DepressionPage from "./pages/depression/DepressionPage";
import DiabetesPage from "./pages/diabetes/DiabetesPage";
import Doctor from "./pages/doctor/Doctor";
import DustAllergyPage from "./pages/dustallergy/DustAllergyPage";
import ErrorPage from "./pages/ErrorPage";
import FoodAllergyPage from "./pages/foodallergy/FoodAllergyPage";
import GalleryPage from "./pages/gallery/GalleryPage";
import GrowthPage from "./pages/growth/GrowthPage";
import HairLossPage from "./pages/hairloss/HairlossPage";
import HomeOne from "./pages/homes/home/HomeOne";
import InfertilityPage from "./pages/infertility/InfertilityPage";
import InnovationTechnology from "./pages/innovation-technology/InnovationTechnology";
import JointPainPage from "./pages/jointpain/JointPainPage";
import Login from "./pages/login/Login";
import LunghealthPage from "./pages/lunghealth/LunghealthPage";
import MedicalTeam from "./pages/medicalteam/MedicalTeam";
import NamrataSrivastav from "./pages/NamrataSrivastav";
import ObesityPage from "./pages/obesity/ObesityPage";
import PatientPhilosophy from "./pages/patient-philosophy/PatientPhilosophy";
import PilesPage from "./pages/piles/PilesPage";
import Register from "./pages/register/Register";
import ResetPassword from "./pages/reset-password/ResetPassword";
import RespiratoryAllergyPage from "./pages/respiratoryallergy/RespiratoryAllergyPage";
import RhinitisallergicPage from "./pages/rhinitisallergic/RhinitisallergicPage";
import SkinAllergyPage from "./pages/skinallergy/SkinAllergyPage";
import TonsilitisPage from "./pages/tonsilitis/TonsilitisPage";
import UrtiPage from "./pages/urti/UrtiPage";
import VideoPage from "./pages/video/VideoPage";
import { APP_ROUTES } from "./utils/constant";


const App: React.FC = () => {
  return (

    <Routes>
      <Route path={APP_ROUTES.HOME} element={<HomeOne />} />
      <Route path={APP_ROUTES.LOGIN} element={<Login />} />
      <Route path={APP_ROUTES.REGISTER} element={<Register />} />
      <Route path={APP_ROUTES.DOCTOR} element={<Doctor />} />
      <Route path={APP_ROUTES.MEDICAL_TEAM} element={<MedicalTeam />} />
      <Route path={APP_ROUTES.ABOUT} element={<About />} />
      <Route path={APP_ROUTES.CONTACT} element={<ContactPage />} />
      <Route path={APP_ROUTES.ABOUT_HOMEOPATHY} element={<AboutHomeopathy />} />
      <Route path={APP_ROUTES.INNOVATION_TECHNOLOGY} element={<InnovationTechnology />} />
      <Route path={APP_ROUTES.PATIENT_PHILOSOPHY} element={<PatientPhilosophy />} />
      <Route path={APP_ROUTES.ASSURED_TREATMENT} element={<AssuredTreatment />} />

      <Route path={APP_ROUTES.APPOINTMENT} element={<AppointmentPage />} />
      <Route path={APP_ROUTES.DR_ARVIND} element={<ArvindSrivastav />} />
      <Route path={APP_ROUTES.DR_NAMRATA} element={<NamrataSrivastav />} />
      <Route path={APP_ROUTES.DR_DEEPMALA} element={<DeepmalaSrivastav />} />
      {/* Disease routes  */}
      <Route path={APP_ROUTES.DISEASE_ASTHMA} element={<AsthmaPage />} />
      <Route path={APP_ROUTES.DISEASE_DEPRESSION} element={<DepressionPage />} />
      <Route path={APP_ROUTES.DISEASE_OBESITY} element={<ObesityPage />} />
      <Route path={APP_ROUTES.DISEASE_AUTISM} element={<AutismPage />} />
      <Route path={APP_ROUTES.DISEASE_GROWTH} element={< GrowthPage />} />
      <Route path={APP_ROUTES.DISEASE_TONSILITIS} element={< TonsilitisPage />} />
      <Route path={APP_ROUTES.DISEASE_URTI} element={< UrtiPage />} />
      <Route path={APP_ROUTES.DISEASE_BRONCHITIS} element={< BronchitisPage />} />
      <Route path={APP_ROUTES.DISEASE_LUNGHEALTH} element={< LunghealthPage />} />
      <Route path={APP_ROUTES.DISEASE_RHINITISALLERGIC} element={<RhinitisallergicPage />} />
      <Route path={APP_ROUTES.DISEASE_DIABETES} element={<DiabetesPage />} />
      <Route path={APP_ROUTES.DISEASE_INFERTILITY} element={<InfertilityPage />} />
      <Route path={APP_ROUTES.DISEASE_JOINTPAIN} element={<JointPainPage />} />
      <Route path={APP_ROUTES.DISEASE_PILES} element={<PilesPage />} />
      <Route path={APP_ROUTES.DISEASE_SKIN_ALLERGY} element={<SkinAllergyPage />} />
      <Route path={APP_ROUTES.DISEASE_FOOD_ALLERGY} element={<FoodAllergyPage />} />
      <Route path={APP_ROUTES.DISEASE_RESPIRATORY_ALLERGY} element={<RespiratoryAllergyPage />} />
      <Route path={APP_ROUTES.DISEASE_DUST_ALLERGY} element={<DustAllergyPage />} />
      <Route path={APP_ROUTES.HAIR_LOSS} element={<HairLossPage />} />
      {/* Video, Gallery  */}
      <Route path={APP_ROUTES.VIDEO} element={<VideoPage />} />
      <Route path={APP_ROUTES.GALLERY} element={<GalleryPage />} />
      <Route path={APP_ROUTES.RESET_PASSWORD} element={<ResetPassword />} />

      <Route element={<RequireAuth />}>
        <Route path={APP_ROUTES.APPOINTMENT_LIST} element={<AppointmentListPage />} />
      </Route>
      <Route path={APP_ROUTES.CHECKOUT} element={<CheckoutPage />} />
      {/* Catch All other path */}
      <Route path={"*"} element={<ErrorPage />} />


    </Routes>
  );
}

export default App;

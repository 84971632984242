
import { Link, useNavigate } from 'react-router-dom';
import arvindImgUrl from '../../assets/img/doctor/arvind2.jpg';
import namrataImgUrl from '../../assets/img/doctor/namrata2.jpg';
import { APP_ROUTES, DR_QUALIFICATION } from '../../utils/constant';

const hiring_content = {
  sub_title: "About Us",
  title: "Short Story About Arvind Srivastava.",
  sm_des: "Dr. Arvind Srivastava is a dedicated and compassionate BHMS-qualified doctor committed to promoting holistic healing through homeopathy. Having 19+ years of experience in the field, he strives to provide personalized and natural healthcare solutions to his patients.",
  features_list: [
    DR_QUALIFICATION.ARVIND_1,
    DR_QUALIFICATION.ARVIND_4,
    DR_QUALIFICATION.ARVIND_6,
  ]
}
const namrata_content = {
  sub_title1: "About Us",
  title1: "Short Story About Namrata Srivastava.",
  sm_des1: "Dr. Namrata Srivastava is a dedicated and compassionate BHMS-qualified doctor with 21+ years experience in homeopathic healthcare. Her journey in the field of homeopathy is marked by a commitment to providing natural and personalized healing solutions to her patients.",
  features_list1: [
    DR_QUALIFICATION.NAMRATA_1,
    DR_QUALIFICATION.NAMRATA_2,
    DR_QUALIFICATION.NAMRATA_3,
    DR_QUALIFICATION.NAMRATA_4,
  ]
}
const { sub_title, title, sm_des, features_list } = hiring_content;
const { sub_title1, title1, sm_des1, features_list1 } = namrata_content;

const HiringAreaHomeThree = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="hiring-area pos-rel">
        <div className="hiring-top">
          <div className="hire-left-img" style={{ backgroundImage: `url(${arvindImgUrl})`, backgroundSize: 'cover' }}></div>
          <div className="container-fluid pl-0 pr-0">
            <div className="row gx-0 overflow-hidden">
              <div className="col-xl-6 offset-xl-6 offset-lg-4">
                <div className="hire-text hire-text-2">
                  <div className="about-title mb-20">
                    <h5>{sub_title}</h5>
                    <h1>{title}</h1>
                  </div>
                  <div className="about-text">
                    <p>{sm_des}</p>
                  </div>
                  <ul className="professinals-list pt-10 pb-20">
                    {features_list.map((item, i) =>
                      <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                    )}
                  </ul>
                  <div className="hiring-button">
                    <button data-animation="fadeInLeft" data-delay=".6s"
                      className="btn btn-icon btn-icon-green ml-0" onClick={() => navigate(APP_ROUTES.DR_ARVIND)}><span>+</span>Know More</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hiring-bottom pos-rel">
          <div className="hire-right-img" style={{ backgroundImage: `url(${namrataImgUrl})`, backgroundSize: 'cover' }}></div>
          <div className="container-fulid pl-0 pr-0">
            <div className="row gx-0 overflow-hidden">
              <div className="col-xl-6 col-lg-8">
                <div className="hire-text hire-text-2">
                  <div className="about-title mb-20">
                    <h5>{sub_title1}</h5>
                    <h1>{title1}</h1>
                  </div>
                  <div className="about-text">
                    <p>{sm_des1}</p>
                  </div>
                  <ul className="professinals-list pt-10 pb-20">
                    {features_list1.map((item, i) =>
                      <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                    )}
                  </ul>
                  <div className="hiring-button">
                    <Link data-animation="fadeInLeft" data-delay=".6s" to={APP_ROUTES.DR_NAMRATA} className="btn btn-icon ml-0">
                      <span>+</span>Know More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HiringAreaHomeThree;
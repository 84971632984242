
import ajay_pandey_img from "../assets/img/icon/ajay-pandey.jpg";
import prtik_jain_img from "../assets/img/icon/pratik-jain.jpg";
import priyanka_chauhan_img from "../assets/img/icon/priyanka-chauhan.jpg";
import rahul_kumar_img from "../assets/img/icon/rahul-kumar.jpeg";
import saikiran_reddy_img from "../assets/img/icon/saikiran-reddy.jpeg";
import salman_ansari_img from "../assets/img/icon/salman-ansari.jpg";


import avatar_img_4_1 from "../assets/img/home4/testimonials/pratik-jain.jpg";
import avatar_img_4_2 from "../assets/img/home4/testimonials/rahul-kumar.jpeg";
import avatar_img_4_3 from "../assets/img/home4/testimonials/saikiran-reddy.jpeg";

interface DataType {
  id: number;
  home?: number;
  title?: string;
  description: string;
  img: string;
  name: string;
  job_title: string;
}


const testimonials_data: DataType[] = [
  {
    id: 1,
    title: "Patient from Jaipur, Rajastan. Very good experience @Dr Arvind Srivastav",
    description: "Very good experience @Dr Arvind Srivastav, got visible result in few weeks. My sincere thanks to each and every staff here and special thanks to Dr Arvind Srivastava.",
    img: prtik_jain_img,
    name: "Pratik Jain",
    job_title: "Business Analyst",
  },
  {
    id: 2,
    title: "Patient from Karnataka. Thanks to Shivam Health Care",
    description: "I was suffering from allergy, cough and was regularly on antibiotics and other pills yet no relief. I found online Shivam Health Care and consulted with Dr Arvind Srivastava and now I'm completely off the antibiotics.",
    img: saikiran_reddy_img,
    name: "Saikiran Reddy",
    job_title: "Software Engineer",
  },
  {
    id: 3,
    title: "Patient from Gorakhpur, Thank you very much the SHC Team",
    description: "Thank you very much for all the support you've provided me during the course of my treatment. It's not an easy journey but each one of you helped me along each step of the way. Thank you for everything you've done for me.",
    img: rahul_kumar_img,
    name: "Rahul Kumar",
    job_title: "Businessman",
  },
  // update
  {
    id: 4,
    title: "Patient from Varanasi, Thank you @Dr Arvind Srivastava",
    description: "Shivam Health Care enlightened our life with great blessing and effort. Today we are blessed with our son Arihant Pandey after 5 years. Our sincere thanks to each and every staff here and special thanks to Dr Namrata Srivastava.",
    img: ajay_pandey_img,
    name: "Ajay Pandey",
    job_title: "Government Teacher",
  },
  {
    id: 5,
    title: "Patient from Kanpur, Special Thanks to @Dr Namrata Srivastava.",
    description: "I am very thankful to Dr Namrata Srivastava and all the other nurses and Staff. Dr Namrata is truly amazing, as well as the staff are really good. I would strongly recommend this hospital to others. Thank you all very much.",
    img: priyanka_chauhan_img,
    name: "Mrs. Priyanka Chauhan - Mr. Deepak Chauhan",
    job_title: "UP Privahan",
  },
  {
    id: 6,
    title: "Patient from Mau, Thanks to @Dr Arvind Srivastava.",
    description: "Started treatment with Dr Arvind Srivastava for my son's skin problem. The results are satisfactory and overall my child's immunity has increased. Pleased with personal attention and reminders given by the clinic on every appointment.",
    img: salman_ansari_img,
    name: "Salman Ansari",
    job_title: "Businessman",
  },

  // home 04 
  {
    id: 1,
    home: 4,
    description: "Exercitation consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. nisi ut aliquip Lorem ipsum dolor sit amet.",
    img: avatar_img_4_1,
    name: "Hiliam D. Pawlu",
    job_title: "Founder, TrashTheme",
  },
  {
    id: 2,
    home: 4,
    description: "Adipisicing exercitation consectetur elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. nisi ut aliquip Lorem ipsum dolor sit amet.",
    img: avatar_img_4_2,
    name: "Eric Z. Piedie",
    job_title: "Founder, TrashTheme",
  },
  {
    id: 3,
    home: 4,
    description: "Incididunt exercitation consectetur adipisicing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. nisi ut aliquip Lorem ipsum dolor sit amet.",
    img: avatar_img_4_3,
    name: "Eric Z. Piedie",
    job_title: "Founder, TrashTheme",
  },
  {
    id: 4,
    home: 4,
    description: "Consectetur exercitation adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. nisi ut aliquip Lorem ipsum dolor sit amet.",
    img: avatar_img_4_1,
    name: "Eric Z. Piedie",
    job_title: "Founder, TrashTheme",
  },

]
export default testimonials_data

import { useState } from 'react';
import blog_img from "../../assets/img/blog/b6.jpg";
import CommentPosts from '../../compoments/disease/CommentPosts';
import DiseaseAuthor from '../../compoments/disease/DiseaseAuthor';
import VideoPopup from '../../modals/VideoPopup';
import { SH_DOCTOR } from '../../utils/constant';
import SkinAllergy from './skinallergy';

const disease_details_data: SkinAllergy = {
    time: "September 15, 2023",
    post_writer: SH_DOCTOR.DR_ARVIND,
    comments: 24,
    about: {
        title: "About Skin Allergy",
        description_1: "Homeopathy is a holistic system of medicine that aims to stimulate the body's own healing mechanisms. A skin allergy is when skin becomes irritated because the immune system reacted to something that is usually harmless. This is called an allergic reaction. An allergic reaction can cause rash, itching, burning, redness, bumps, hives, and swelling.. Homeopathy treats the root cause of the ailment, promoting long-term well-being.",
        description_2: "Homeopathic remedies are highly diluted substances, often prepared through a process called potentization. This ensures that the remedies are safe and gentle, with minimal risk of side effects.",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    impact: {
        title: "Skin Impact",
        description_1: "Your skin protects your body from germs and regulates body temperature. Nerves in the skin help you feel sensations like hot and cold. Your skin, along with your hair, nails, oil glands and sweat glands, is part of the integumentary (in-TEG-you-ME I NT-a-ree) system. “Integumentary” means a body's outer covering.",
        description_2: "",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    symptoms: {
        title: "Symptoms",
        description_1: "Discolored skin patches (abnormal pigmentation).",
        description_2: "Dry skin.",
        description_3: "Open sores, lesions or ulcers.",
        description_4: "Peeling skin.",
        description_5: "Rashes, possibly with itchiness or pain.",
        description_6: "",
        list_1: ["Red, white or pus-filled bumps.",
            "Scaly or rough skin.",
            "Vitiligo. Vitiligo is a disorder that causes patches of skin to become white.",
            "Pachyonychia Congenita."],
        list_2: [],
        list_3: []
    },
    cause: {
        title: "Cause",
        description_1: "Skin diseases are conditions that affect your skin. These diseases may cause rashes, inflammation, itchiness or other skin changes. Some skin conditions may be genetic, while lifestyle factors may cause others. Skin disease treatment may include medications, creams or ointments, or lifestyle changes.",
        description_2: "",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [
            "Acne, blocked skin follicles that lead to oil, bacteria and dead skin buildup in your pores.",
            "Alopecia areata, losing your hair in small patches.",
            "Atopic dermatitis (eczema), dry, itchy skin that leads to swelling, cracking or scaliness.",
            "Psoriasis, scaly skin that may swell or feel hot.",
            "Raynaud’s phenomenon, periodic reduced blood flow to your fingers, toes or other body parts, causing numbness or skin color change.",
            "Skin cancer, uncontrolled growth of abnormal skin cells."
        ],
        list_2: [],
        list_3: []
    },
    treatment: {
        title: "Treatment",
        description_1: "This Common homeopathic medicine is used to treat a number of skin conditions. the symptoms it can address are: Moist scabby eruptions on joints, between fingers, behind the ears or on the scalp,Dry and scaly skin,Fissured eczema that bleeds or has a thick gooey discharge,Itchy eczema on the scalp and face.",
        description_2: "Homeopathic skin treatment treats the root cause of the condition, it is natural, safe, and effective. Adding lifestyle modifications, exercise, and a balanced diet to homeopathic skin treatment can help improve skin function and lessen symptoms of the condition.",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    qoute_text: "A substance that can artificially produce certain disease-like symptoms on a healthy person; only that substance can cure a similar disease when it is given to the patient in the form of homeopathic medicine.",
    qoute_writer: "Homeopathy Principle",
    comments_data: [
        {
            id: 1,
            img: 'comments1.png',
            name: "Riya Singh",
            time: "19th May 2023",
            comment_text: "Best tretment for skin issues",

        },
        {
            id: 2,
            img: 'comments2.png',
            name: "Annya Singh",
            time: "19th May 2023",
            comment_text: "Aggred, I am also take the tretment from Shivam Hospital & it's provide the better mediciens",

        },
        {
            id: 3,
            img: 'comments1.png',
            name: "Mohit",
            time: "19th May 2023",
            comment_text: "I am also satisfied for skin tretment. ",

        },

    ]

}

const SkinAllergyInnerArea = () => {
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    return (
        <>
            <div className="col">
                <article className="postbox post format-image mb-40">
                    <div className="postbox__text bg-none">

                        <h3 className="blog-title">{disease_details_data.about.title}</h3>
                        <div className="post-text mb-20">
                            <p>{disease_details_data.about.description_1}</p>
                            {/* <p>{disease_details_data.description_2}</p> */}
                            <blockquote>
                                <p>{disease_details_data.qoute_text}</p>
                                <footer>- {disease_details_data.qoute_writer}</footer>
                            </blockquote>
                            <h3 className="blog-title">{disease_details_data.impact.title}</h3>
                            <p>{disease_details_data.impact.description_1}</p>

                            <div className="blog-inner-img mb-30 mt-30">
                                <img src={blog_img} alt="blog" />
                            </div>

                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.symptoms.title}</h3>
                                <p>{disease_details_data.symptoms.description_1}</p>
                                <p>{disease_details_data.symptoms.description_2}</p>
                                <ul className="professinals-list pt-10 pb-20">
                                    {disease_details_data.symptoms.list_1.map((item, i) =>
                                        <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                                    )}
                                </ul>
                                <p>{disease_details_data.symptoms.description_3}</p>
                            </div>
                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.cause.title}</h3>
                                <p>{disease_details_data.cause.description_1}</p>
                                <ul className="professinals-list pt-10 pb-20">
                                    {disease_details_data.cause.list_1.map((item, i) =>
                                        <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                                    )}
                                </ul>
                            </div>
                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.treatment.title}</h3>
                                <p>{disease_details_data.treatment.description_1}</p>
                                <p>{disease_details_data.treatment.description_2}</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="navigation-border pt-50 mt-40"></div>
                            </div>
                        </div>
                    </div>
                    <DiseaseAuthor avatarImg={'arvind2.jpg'} doctorName={SH_DOCTOR.DR_ARVIND} diseaseCauseDesc={disease_details_data.cause.description_1} />
                    <CommentPosts comment_data={disease_details_data.comments_data} />


                </article>
            </div>

            {/* video modal start */}
            <VideoPopup
                isVideoOpen={isVideoOpen}
                setIsVideoOpen={setIsVideoOpen}
                videoId={'UBdUpt6oTOE'}
            />
            {/* video modal end */}

        </>
    );
};

export default SkinAllergyInnerArea;
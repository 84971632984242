
import { useRef } from 'react';
import Slider from "react-slick";

import GoogleImg from "../../../assets/img/icon/google.png";
import back_icon from "../../../assets/img/section/section-back-icon.png";
import line_icon from "../../../assets/img/shape/section-title-line.png";
import quate_icon from "../../../assets/img/testimonials/testi-quato-icon.png";
import testimonials_data from '../../../data/TestimonialsData';

const setting = {
	dots: false,
	arrows: false,
	infinite: true,
	speed: 300,
	autoplay: true,
	autoplaySpeed: 10000,
	slidesToShow: 2,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true,
			}
		},
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}

		}

	]
}
const GoogleReviewsSliderArea = () => {
	const sliderRef = useRef<Slider | null>(null);
	const handlePrev = () => {
		if (sliderRef.current) {
			sliderRef.current.slickPrev();
		}
	};
	const handleNext = () => {
		if (sliderRef.current) {
			sliderRef.current.slickNext();
		}
	}
	return (
		<>
			<section className="portfolio-area pt-120 pb-90">
				<div className="container">
					<div className="row">
						<div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1 col-md-10 offset-md-1">
							<div className="section-title text-center pos-rel mb-70">
								<div className="section-icon">
									<img className="section-back-icon" src={back_icon} alt="theme-pure" />
								</div>
								<div className="section-text pos-rel">
									<h5>Testimonials</h5>
									<h1>Our Happy Clients Says About Us.</h1>
								</div>
								<div className="section-line pos-rel">
									<img src={line_icon} alt="theme-pure" />
								</div>
							</div>
						</div>
					</div>
					<div className="postbox__gallery mb-35 position-relative">
						<button type="button" className="slick-prev slick-arrow" onClick={handlePrev}>
							<i className="fas fa-arrow-left"></i>
						</button>
						<button type="button" className="slick-next slick-arrow" onClick={handleNext}>
							<i className="fas fa-arrow-right"></i>
						</button>
						<Slider {...setting} ref={sliderRef}>
							{testimonials_data.slice(0, 6).map((item, i) =>
								<div key={i} className="col-xl-12">
									<div className="testi-box-2 ">
										<div className='p-2 m-2 gray-bg' style={{ height: "410px" }}>
											<div className="test-rating-inner d-flex justify-content-between mb-30 align-items-center pr-15">
												<div className="testi-quato-icon testi-quato-icon-green m-0">
													<img src={quate_icon} alt="theme-pure" />
												</div>
												<div className="testi-rating-list d-flex">
													<img src={GoogleImg} height={20} width={20} style={{ marginRight: "4px" }} alt="theme-pure" />
													<ul>
														<li><i className="fas fa-star"></i></li>
														<li><i className="fas fa-star"></i></li>
														<li><i className="fas fa-star"></i></li>
														<li><i className="fas fa-star"></i></li>
														<li><i className="fas fa-star"></i></li>
													</ul>
												</div>
											</div>
											<div className="testi-content-2">
												<h3>{item.title}</h3>
												<p>{item.description}</p>
											</div>
											<div className="testi-author d-flex align-items-center mt-30">
												<div className="testi-author-icon-2">
													<img src={item.img} alt="theme-pure" style={{ borderRadius: "50%" }} />
												</div>
												<div className="testi-author-desination-2">
													<h4>{item.name}</h4>
													<span>Working At <span className="f-500 green-color">{item.job_title}</span></span>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</Slider>
					</div>
				</div>
			</section>
		</>
	);
};

export default GoogleReviewsSliderArea;
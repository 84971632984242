import React, { useState } from 'react';
import { paymentService } from '../../services/payment.service';

const Payment: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState<number>(0);

    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };

    const handlePayment = async () => {
        const res = await loadRazorpayScript();
        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        // const result = await axios.post('http://localhost:5000/api/payment/order', {
        //     amount: amount,
        //     currency: 'INR',
        //     receipt: 'receipt#1'
        // });

        // setLoading(true);
        const result = await paymentService.createOrder({
            amount: amount,
            currency: 'INR',
            receipt: 'receipt#1'
        });


        if (!result) {
            alert('Server error. Are you online?');
            return;
        }

        const { amount: orderAmount, id: orderId, currency } = result.data;

        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY_ID,
            amount: orderAmount,
            currency,
            name: 'Shivam Homeo Care',
            description: 'Test Transaction',
            order_id: orderId,
            handler: async (response: any) => {
                const data = {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature
                };

                const result = await paymentService.verify(data);
                alert(result.data.status);
            },
            prefill: {
                name: 'Your Name',
                email: 'your.email@example.com',
                contact: '9999999999'
            },
            notes: {
                address: 'Razorpay Corporate Office'
            },
            theme: {
                color: '#3399cc'
            }
        };

        const paymentObject = new (window as any).Razorpay(options);
        paymentObject.open();
    };

    return (
        <div>
            <h2>Razorpay Payment Gateway</h2>
            <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
            />
            <button onClick={handlePayment}>Pay with Razorpay</button>
        </div>
    );
};

export default Payment;

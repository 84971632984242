
import { Link } from "react-router-dom";
import popular_feeds_1 from "../../assets/img/blog/details/img1.jpg";
import popular_feeds_2 from "../../assets/img/blog/details/img2.jpg";
import popular_feeds_3 from "../../assets/img/blog/details/img3.jpg";
import { APP_ROUTES } from "../../utils/constant";


interface DataType {
  id: number;
  img: string;
  title: string;
  time: string;
}

const popular_feeds: DataType[] = [
  {
    id: 1,
    img: popular_feeds_1,
    title: "Dolor sit cing elit lorem ipsum , sed do.",
    time: "October 18, 2023",
  },
  {
    id: 2,
    img: popular_feeds_2,
    title: "Dolor sit cing elit lorem ipsum , sed do.",
    time: "October 18, 2023",
  },
  {
    id: 3,
    img: popular_feeds_3,
    title: "Dolor sit cing elit lorem ipsum , sed do.",
    time: "October 18, 2023",
  },
]
const PopularFeeds = ({ popularFeeds }: any) => {
  return (
    <>
      <div className="widget mb-40">
        <div className="widget-title-box mb-30">
          <span className="animate-border"></span>
          <h3 className="widget-title">Popular Feeds</h3>
        </div>
        <ul className="recent-posts">
          {popular_feeds.map((item, i) =>
            <li key={i}>
              <div className="widget-posts-image">
                <Link to={APP_ROUTES.HOME}><img src={item.img} alt="theme-pure" /></Link>
              </div>
              <div className="widget-posts-body">
                <h6 className="widget-posts-title"><Link to={APP_ROUTES.HOME}>{item.title}</Link></h6>
                <div className="widget-posts-meta">{item.time}</div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default PopularFeeds;
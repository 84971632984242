import Breadcrumb from "../../compoments/common/Breadcrumb";
import FooterThree from "../../layout/footers/FooterThree";
import HeaderFive from '../../layout/headers/HeaderFive';
import PatientPhilosophyInnerArea from './PatientPhilosophyInnerArea';

import { useEffect } from "react";
import imgUrlPatientPhil from '../../assets/img/breadcrumb/patient-first-philosophy.jpeg';
import Wrapper from "../../layout/Wrapper";
import { PAGE_TITLE } from "../../utils/constant";

const PatientPhilosophy = () => {

  useEffect(() => {
    // Set the page title when the component mounts
    document.title = PAGE_TITLE.ABOUT_HOMEOPATHIC;

    // Optionally, you can return a cleanup function to reset the title when the component unmounts
    return () => {
      document.title = PAGE_TITLE.HOME;
    };
  }, []);

  return (
    <Wrapper>
      <HeaderFive />
      <main>
        <Breadcrumb sub_title='Your Haven for Holistic Healing.' title='Discover the healing power of homeopathy' page='Patient Philosophy' imgUrl={imgUrlPatientPhil} />
        {/* <BlogArea /> */}
        <section className="blog-area pt-120 pb-80">
          <div className="container">
            <div className="row">
              <PatientPhilosophyInnerArea />
            </div>
          </div>
        </section>
      </main>
      <FooterThree />
    </Wrapper>
  );
};

export default PatientPhilosophy;

import FooterOne from "../../../layout/footers/FooterOne";
import AppointmentAreaHomeTwo from "../home-2/AppointmentAreaHomeTwo";
import AboutAreHomeThree from "./AboutAreHomeThree";
import CtaAreaHomeOne from "./CtaAreaHomeOne";
import FactAreaHomeOne from "./FactAreaHomeOne";
import HeroSliderHomeOne from "./HeroSliderHomeOne";
import LatestNewsAreaHomeOne from "./LatestNewsAreaHomeOne";
import ServicesAreaHome from "./ServicesAreaHome";
import ServicesAreaHomeFour from "./ServicesAreaHomeFour";
import TreatmentAreaHomeFour from "./TreatmentAreaHomeFour";
// import HiringAreaHomeThree from "../../about/HiringAreaHomeThree";
import Wrapper from "../../../layout/Wrapper";
import HeaderFive from "../../../layout/headers/HeaderFive";
import CounterSectionHomeTwo from "../home-2/CounterSectionHomeTwo";
import GoogleReviewsSliderArea from "./GoogleReviewsSliderArea";

const HomeOne = () => {
  return (
    <Wrapper>
      <HeaderFive />
      <main>
        <HeroSliderHomeOne />
        <AboutAreHomeThree />
        <ServicesAreaHomeFour style={{ style: true }} />
        <CounterSectionHomeTwo />
        <AppointmentAreaHomeTwo />
        <TreatmentAreaHomeFour />
        <FactAreaHomeOne />
        <ServicesAreaHome />
        <CtaAreaHomeOne />
        {/* <HiringAreaHomeThree /> */}
        <GoogleReviewsSliderArea />
        <LatestNewsAreaHomeOne style={{ style: false }} />
      </main>
      <FooterOne />
    </Wrapper>
  );
};

export default HomeOne;


import imgUrlRhinitisallergic from '../../assets/img/disease/rhinitisallergic.jpeg';
import Breadcrumb from '../../compoments/common/Breadcrumb';
import Wrapper from '../../layout/Wrapper';
import FooterThree from '../../layout/footers/FooterThree';
import HeaderFive from '../../layout/headers/HeaderFive';
import DustAllergyInnerArea from './RhinitisallergicInnerArea';

const RhinitisallergicPage = () => {
  return (
    <Wrapper>
      <HeaderFive />
      <main>
        <Breadcrumb sub_title='Your Heaven for Holistic Healing.' title='Discover the healing power of homeopathy' page='Rhinitis Allergic' imgUrl={imgUrlRhinitisallergic} />
        {/* <BlogArea /> */}
        <section className="blog-area pt-120 pb-80">
          <div className="container">
            <div className="row">
              <DustAllergyInnerArea />
            </div>
          </div>
        </section>
      </main>
      <FooterThree />
    </Wrapper>
  );
};

export default RhinitisallergicPage;

import arvindProfileImg from '../assets/img/doctor/arvind2.jpg';
import deepmalaProfileImg from '../assets/img/doctor/drdeepmala.jpeg';
import namrataProfileImg from '../assets/img/doctor/namrata2.jpg';
import icon_1 from "../assets/img/services/hair-loss.png";
import icon_1_2 from "../assets/img/services/hair-loss2.png";
import icon_2 from "../assets/img/services/infertility.png";
import icon_2_2 from "../assets/img/services/infertility2.png";
import icon_3_2 from "../assets/img/services/men-health.png";
import degree_1 from "../assets/img/services/more-ser-1.png";
import degree_2 from "../assets/img/services/more-ser-5.png";
import icon_4 from "../assets/img/services/skincare.png";
import icon_3 from "../assets/img/services/women-health.png";
import { SH_DOCTOR } from "../utils/constant";

const doctor_detail_data = {
    arvind: {
        title: SH_DOCTOR.DR_ARVIND,
        subTitle: "Reproductive Endocronology and infertility",
        page: SH_DOCTOR.DR_ARVIND,
        coverImgUrl: SH_DOCTOR.DR_ARVIND_COVER_IMG,
        code: 'ARVIND'
    },
    namrata: {
        title: SH_DOCTOR.DR_NAMRATA,
        subTitle: "Reproductive Endocronology and infertility",
        page: SH_DOCTOR.DR_NAMRATA,
        coverImgUrl: SH_DOCTOR.DR_NAMRATA_COVER_IMG,
        code: 'NAMRATA'
    },
    deepmala: {
        title: SH_DOCTOR.DR_DEEPMALA,
        subTitle: "Reproductive Endocronology and infertility",
        page: SH_DOCTOR.DR_DEEPMALA,
        coverImgUrl: SH_DOCTOR.DR_DEEPMALA_COVER_IMG,
        code: 'DEEPMALA'
    }
}



const doctor_details_content = {
    arvind: {
        profileImg: arvindProfileImg,
        sub_title: "introducing my self",
        title: "BHMS(Agra), M.D(Hons), JRF",
        des_1: "Dr Arvind Srivastava was born in Ballia UP he completed his basic education from Ballia being an excellent student throughout he got admission in Bachelor of Homoeopathic Medicine and Surgery B.H.M.S. At government Homoeopathic Medical College and Hospital Ghazipur UP after completing his medical graduation BHMS in top rank he got admission in post graduation MD home Rajasthan.",
        des_1_2: "Dr Arvind Srivastva: Jrn Rajasthan Vidyapeeth University Udaipur Rajasthan under the guidance of Dr SK Bhattacharjee director of the National institute of Homoeopathy, Salt Lake Kolkata A model post graduate institute of Homoeopathy in India after that he was selected as Junior Research Fellow in government Homoeopathic Medical College and Hospital Ghazipur UP and researched on Indian drugs.",
        des_1_3: "He was selected at the post of Government Homoeopathic Medical Officer from Uttar Pradesh Public Service Commission Allahabad UP in first attempt and posted as Homoeopathic Medical officer in Mau UP att Government Homoeopathic Hospital he had daily consulted 100 to 150 patience of almost every kind.",
        des_1_4: "Considering the extra qualification MD JRF the UP government sent him in the Government Homoeopathic Medical College Ghazipur UP at the post of lecturer on deputation. He tought Homoeopathic metaria medica. He now appointed at the post of Senior Homoeopathic Medical Officer . A lot of people suffering from server diseases were cured by him.",
        des_1_5: 'Dr. Srivastava also have keen interest in Reasearch Work and the social activities. More than 100 free medical camps Health awareness campaigns, Vote campaigns etc . He had got many awards from serveral institutions like "Best Teacher Award" from The Homoeopathic medical Association of India UP State, "Jagran Genius award", "Jagran Best Doctor Awards", "Jagran Corona Yodha Samman", Dr. Shrivtastav always say that Homoeopathic treatment is much more better, easier to take, cheaper in cost, curable from root in comparison to other system of treatments.',
        des_1_6: "Doctor shrivtastav try to understand his every patient holistically consider all the sign and symptoms at physical, mental, social, spiritual and emotional level and then treat rationally",
        skills_text: "Here Is Skills",
        des_2: "He is the specialist of certain chronic diseases namely, Asthma and Allergy, Cerebral Palsy,Mental retardation, Autism Spectrum Disorder, Pediatric Neurology Disease, Uncommon Genetic Disorder like, Mentally Retarded Children, Down Syndrome, Dyslexia, Oligospermia, Male & Female Infertility, Recurrent Abortions, MDR Tuberculosis, Infective Hepatitis, Height Increase in children, any type food allergy like..wheat allergy,egg allergy,milk allergy etc.",
        features: [
            {
                id: 1,
                img: icon_1_2,
                title: "Hair Loss",
                lists: [
                    "Alopecia Areata.",
                    "Pattern Baldness.",
                    "Traction Alopecia",
                    "Telogen Effluvium",
                ],
            },
            {
                id: 2,
                img: icon_2_2,
                title: "Infertility",
                lists: [
                    "Low Sperm Count.",
                    "Varicocele.",
                    "Erectile Dysfunction.",
                    "Poor Sperm Motility.",
                ],
            },
            {
                id: 3,
                img: icon_3_2,
                title: "Men Health",
                lists: [
                    "Anxiety and Stress.",
                    "Low Testosterone Levels.",
                    "Urinary Tract Infections.",
                    "Prostate Enlargement.",
                ],
            },
            {
                id: 4,
                img: icon_4,
                title: "Skin Care",
                lists: [
                    "Eczema",
                    "Psoriasis/Vitiligo",
                    "Acne",
                    "Urticaria (Hives)",
                ],
            },
        ],
        care_text: "Care Coverage",
        des_3: " Dr. Arvind Kumar Srivastava is passionate for managing patients with the personalized care that is suitable to their very own needs. He always believes in taking a comprehensive approach towards better healthcare, taking into account patient’s physical and mental well-being. Dr. Arvind Kumar Srivastava 19 years of medical expertise and tremendous knowledge under his belt, that’s the reason for his successful practice. His tireless efforts has been a boon to many patients and his enthusiasm has proven to be great driver for his excellence.",
        name: SH_DOCTOR.DR_ARVIND,
        job_title: SH_DOCTOR.DR_ARVIND_QUALIFICATION,
        qualifications: "Qualifications",
        qualifications_data: [
            {
                id: 1,
                img: degree_1,
                education: "BHMS",
                versity_name: "Agra University (2000 - 2003)",
            },
            {
                id: 2,
                img: degree_2,
                education: "MD Homeopathy Materia",
                versity_name: "Rajasthan University (2004 - 2006)",
            },
        ],
        advice: "Get Some Advice?",
        speciality: [
            'Diabetese', 'Skin Specialist', 'Dermatology', 'Infertility'
        ],
        language: [
            'eng', 'hindi', 'bhjpr'
        ]
    },
    namrata: {
        profileImg: namrataProfileImg,
        sub_title: "introducing my self",
        title: "BHMS(Agra), DRCH(Fatima Hospital)",
        des_1: "Dr. Namrata Srivastava was born in Kanpur. She did her schooling from an esteemed educational institute Mariampur senior secondary school, Kanpur. Being a bright student throughout the academics she got selected in Government Homoeopathic Medical college, Gazipur (U.P) in the first attempt. After graduating B.H.M.S from the college in Top holding Rank she took a degree of P G Diploma in Reproductive and child health (D.R.C.H) from a Missionary Hospital Fatima hospital, Mau.",
        des_1_2: "Being a topper student throughout the the acadamics she has a great command over Materia medica, Organanon and applied Practice of Medicine.",
        des_1_3: "She got selected from Uttar Pradesh Public Service Commission in 2010 as Government Homoeopathic medical officer. Since then sees around 150-200 patients per day. Such volume requires use of extensive ready knowledge of clinical and comparative materia medica coupled with profound intuition. Many people have benefited till now from her homoeopathic treatment regarding a vast range of ailments.",
        des_1_4: "Apart from excelling in educational profile throughout Dr. Namrata Srivastava takes special interest in social activities related to Health and Education. She is often invited in various educational Institutions to deliver motivational speech for students. Recently she was invited at Indian Institute of Seed Science to deliver a lecture on the importance of Ragi, Millets, whole grains in eradicating malnutrition.",
        des_1_5: "She is also chosen in the team of Mission Shakti campaign by the UP Government in Mau district. She has been awarded with dozens of awards and trophies in her bag of achievements and accolades. The significant ones being;",
        des_1_6: "",
        skills_text: "Here Is Skills",
        des_2: " She is a practicing doctor for the last 21 years and started  Shivam Health Care & Research Center. With over 21 years of experience in Homeopathy and Healthcare management, she has done more than 1 lakh consultations which include cases of hair loss, alopecia areata, Skin diseases such as psoriasis, vitiligo, eczema and skin allergies (Urticaria). Respiratory cases such as allergic rhinitis, tonsillitis, bronchitis, asthma, COPD, children's cases such as childhood asthma, ADHD (attention deficit hyperactivity disorder), autism, adenoids, nocturnal enuresis, premature greying of hair etc. Stress management cases such as depression, anxiety, phobias, and other cases such as PCOS, thyroid disorders, diabetes, migraine, arthritis, and gastric disorders, women's health disorders just to name a few.",
        features: [
            {
                id: 1,
                img: icon_1,
                title: "Hair Loss",
                lists: [
                    "Alopecia Areata.",
                    "Pattern Baldness.",
                    "Traction Alopecia",
                    "Telogen Effluvium",
                ],
            },
            {
                id: 2,
                img: icon_2,
                title: "Infertility",
                lists: [
                    "Ovulatory Disorders.",
                    "Hormonal Imbalances.",
                    "Blocked Fallopian Tubes.",
                    "Luteal Phase Defect.",
                ],
            },
            {
                id: 3,
                img: icon_3,
                title: "Women Health",
                lists: [
                    "PCOS/PCOD.",
                    "Leucorrhoea.",
                    "Thyroid",
                    "Menopause",
                ],
            },
            {
                id: 4,
                img: icon_4,
                title: "Skin Care",
                lists: [
                    "Eczema",
                    "Psoriasis/Vitiligo",
                    "Acne",
                    "Urticaria (Hives)",
                ],
            },
        ],
        care_text: "Care Coverage",
        des_3: " Dr. Namrata Srivastava in the importance of preventive healthcare and is committed to helping her patients maintain optimal health and well-being. She works with her patients to develop strategies for preventing illness and maintaining a healthy lifestyle. As a Head of Shivam Health Care & Research Center, Dr. Namrata Srivastava is dedicated to helping her patients learn about their health and make informed decisions about their healthcare. She provides guidance and support to her patients, as well as the other doctors training helping them navigate complex healthcare issues.",
        name: SH_DOCTOR.DR_NAMRATA,
        job_title: SH_DOCTOR.DR_NAMRATA_QUALIFICATION,
        qualifications: "Qualifications",
        qualifications_data: [
            {
                id: 1,
                img: degree_1,
                education: "Bachelor of Homoeopathic Medicine and Surgery",
                versity_name: "Government Homeopathic Medical College and Hospital (2000 - 2003)", //TOASK year
            },
            {
                id: 2,
                img: degree_2,
                education: "Diploma in Reproductive and Child Health",
                versity_name: "Rajasthan University (2004 - 2006)", //TOASK year
            },
        ],
        advice: "Get Some Advice?",
        speciality: [
            'Gynaecology', 'Skin Specialist', 'Dermatology', 'Infertility'
        ],
        language: [
            'eng', 'hindi', 'bhjpr'
        ]
    },
    deepmala: {
        profileImg: deepmalaProfileImg,
        sub_title: "introducing my self",
        title: "BHMS",
        des_1: "Dr. Deepmala Srivastava was born in Kanpur. She did her schooling from an esteemed educational institute Mariampur senior secondary school, Kanpur. Being a bright student throughout the academics she got selected in Government Homoeopathic Medical college, Gazipur (U.P) in the first attempt. After graduating B.H.M.S from the college in Top holding Rank she took a degree of P G Diploma in Reproductive and child health (D.R.C.H) from a Missionary Hospital Fatima hospital, Mau.",
        des_1_2: "Being a topper student throughout the the acadamics she has a great command over Materia medica, Organanon and applied Practice of Medicine.",
        des_1_3: "She got selected from Uttar Pradesh Public Service Commission in 2010 as Government Homoeopathic medical officer. Since then sees around 150-200 patients per day. Such volume requires use of extensive ready knowledge of clinical and comparative materia medica coupled with profound intuition. Many people have benefited till now from her homoeopathic treatment regarding a vast range of ailments.",
        des_1_4: "Apart from excelling in educational profile throughout Dr. Namrata Srivastava takes special interest in social activities related to Health and Education. She is often invited in various educational Institutions to deliver motivational speech for students. Recently she was invited at Indian Institute of Seed Science to deliver a lecture on the importance of Ragi, Millets, whole grains in eradicating malnutrition.",
        des_1_5: "She is also chosen in the team of Mission Shakti campaign by the UP Government in Mau district. She has been awarded with dozens of awards and trophies in her bag of achievements and accolades. The significant ones being;",
        des_1_6: "",
        skills_text: "Here Is Skills",
        des_2: " She is a practicing doctor for the last 6+ years and started  Shivam Health Care & Research Center. With over 21 years of experience in Homeopathy and Healthcare management, she has done more than 1 lakh consultations which include cases of hair loss, alopecia areata, Skin diseases such as psoriasis, vitiligo, eczema and skin allergies (Urticaria). Respiratory cases such as allergic rhinitis, tonsillitis, bronchitis, asthma, COPD, children's cases such as childhood asthma, ADHD (attention deficit hyperactivity disorder), autism, adenoids, nocturnal enuresis, premature greying of hair etc. Stress management cases such as depression, anxiety, phobias, and other cases such as PCOS, thyroid disorders, diabetes, migraine, arthritis, and gastric disorders, women's health disorders just to name a few.",
        features: [
            {
                id: 1,
                img: icon_1,
                title: "Hair Loss",
                lists: [
                    "Alopecia Areata.",
                    "Pattern Baldness.",
                    "Traction Alopecia",
                    "Telogen Effluvium",
                ],
            },
            {
                id: 2,
                img: icon_2,
                title: "Infertility",
                lists: [
                    "Ovulatory Disorders.",
                    "Hormonal Imbalances.",
                    "Blocked Fallopian Tubes.",
                    "Luteal Phase Defect.",
                ],
            },
            {
                id: 3,
                img: icon_3,
                title: "Women Health",
                lists: [
                    "PCOS/PCOD.",
                    "Leucorrhoea.",
                    "Thyroid",
                    "Menopause",
                ],
            },
            {
                id: 4,
                img: icon_4,
                title: "Skin Care",
                lists: [
                    "Eczema",
                    "Psoriasis/Vitiligo",
                    "Acne",
                    "Urticaria (Hives)",
                ],
            },
        ],
        care_text: "Care Coverage",
        des_3: " Dr. Namrata Srivastava in the importance of preventive healthcare and is committed to helping her patients maintain optimal health and well-being. She works with her patients to develop strategies for preventing illness and maintaining a healthy lifestyle. As a Head of Shivam Health Care & Research Center, Dr. Namrata Srivastava is dedicated to helping her patients learn about their health and make informed decisions about their healthcare. She provides guidance and support to her patients, as well as the other doctors training helping them navigate complex healthcare issues.",
        name: SH_DOCTOR.DR_DEEPMALA,
        job_title: SH_DOCTOR.DR_DEEPMALA_QUALIFICATION,
        qualifications: "Qualifications",
        qualifications_data: [
            {
                id: 1,
                img: degree_1,
                education: "Bachelor of Homoeopathic Medicine and Surgery",
                versity_name: "Government Homeopathic Medical College and Hospital (2000 - 2003)", //TOASK year
            },
            {
                id: 2,
                img: degree_2,
                education: "Diploma in Reproductive and Child Health",
                versity_name: "Rajasthan University (2004 - 2006)", //TOASK year
            },
        ],
        advice: "Get Some Advice?",
        speciality: [
            'Gynaecology', 'Skin Specialist', 'Dermatology', 'Infertility'
        ],
        language: [
            'eng', 'hindi', 'bhjpr'
        ]
    }

}


export { doctor_detail_data, doctor_details_content };


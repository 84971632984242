import React from "react";
import { Link } from "react-router-dom";
import { Comment } from "../../types/comment";
import CommentsForm from "../forms/CommentsForm";


interface Props {
    comment_data: Comment[]
}

const CommentPosts: React.FC<Props> = ({ comment_data }) => {

    return (
        <>
            <div className="post-comments">
                <div className="blog-coment-title mb-30">
                    <h2>03 Comments</h2>
                </div>
                <div className="latest-comments">
                    <ul>
                        {comment_data.map((comment: Comment, index: number) =>
                            <li key={index} className={index === 1 ? "children" : ""}>
                                <div className="comments-box">
                                    <div className="comments-avatar">
                                        <img src={require(`../../assets/img/blog/details/${comment.img}`)} alt="title" />
                                    </div>
                                    <div className="comments-text">
                                        <div className="avatar-name">
                                            <h5>{comment.name}</h5>
                                            <span>{comment.time}</span>
                                            <Link className="reply" to="#"><i className="fas fa-reply"></i>Reply</Link>
                                        </div>
                                        <p>{comment.comment_text}</p>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>

            <div className="post-comments-form">
                <div className="post-comments-title">
                    <h2>Post Comments</h2>
                </div>
                <CommentsForm />
            </div>
        </>
    )
};

export default CommentPosts;

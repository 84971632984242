import axiosInstance from "./axios-instance";


class PaymentService {
    private static POST_CREATE_ORDER_API_URL = '/api/payment/order';
    private static POST_VERIFY_API_URL = '/api/payment/verify';

    public createOrder = async (data: any) => {
        return await axiosInstance.post(PaymentService.POST_CREATE_ORDER_API_URL, data);
    }

    public verify = async (data: any) => {
        return await axiosInstance.get(PaymentService.POST_VERIFY_API_URL, data);
    }

}

export const paymentService = new PaymentService();
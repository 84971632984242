
import imgUrl from '../../assets/img/bg/routine__bg.jpg';
import Breadcrumb from '../../compoments/common/Breadcrumb';
import FooterThree from '../../layout/footers/FooterThree';
import HeaderFive from '../../layout/headers/HeaderFive';
import TeamAreaHomeOne from '../homes/home/TeamAreaHomeOne';


const Doctor = () => {
  return (
    <>
      <HeaderFive />
      <main>
        <Breadcrumb sub_title='We are here for your care.' title='Doctor' page='Doctor' imgUrl={imgUrl} />
        <TeamAreaHomeOne />
      </main>
      <FooterThree />
    </>
  );
};

export default Doctor;
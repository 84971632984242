import { useNavigate } from 'react-router-dom';
import imgUrl from '../../assets/img/bg/appointment.jpg';
import { APP_ROUTES } from '../../utils/constant';


type DataType = {
  sub_title: string;
  title: string;
  sm_des: string;
  features: string[];
}
const about_appoin_content: DataType = {
  sub_title: "Our Approch",
  title: "If you are a patient seeking quality.",
  sm_des: "If you are looking for the seamless integration of the traditional homeopathic techniques with the latest research from the field of medicine, Shivam Homoeopathy Hospital & Research Centre (SHHARC) is the best option. We strive to deliver high-quality homeopathic treatment and exact disease diagnosis through our team of highly professional doctors and medical experts. We guarantee to offer best medical treatment in the medical industry because we use latest and refined medical tools available till date.",
  features: [
    "Meet the health needs of the people providing high quality, comprehensive healthcare.",
    "Get the health need of the people providing high quality, comprehensive healthcare to all and strive to be the primer health care.",
    "Find the holistic (Mental, physical and spiritual) well being of each individual with cost effective homoeopathic medicine.",
    "SHC is well-established as a zero side effects medical providers as well as rapid recovery solution contributors from any medical conditions.",
  ]
}
const { sub_title, title, sm_des, features } = about_appoin_content

const AboutAppointment = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="appointment-section pt-120 pb-120" style={{ backgroundImage: `url(${imgUrl})` }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="appointment-box white">
                <div className="appointment-content">
                  <span className="small-text">{sub_title}</span>
                  <h2>{title}</h2>
                  <p>{sm_des}</p>
                  <ul className="professinals-list pt-30">
                    {features.map((item, i) =>
                      <li key={i}>
                        <i className="fa fa-check"></i>
                        {item}
                      </li>
                    )}
                  </ul>
                </div>
                <button className="primary_btn btn mt-40" onClick={() => navigate(APP_ROUTES.APPOINTMENT)}>make appointment</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutAppointment;

import { useState } from 'react';
import blog_img from "../../assets/img/blog/b6.jpg";
import CommentPosts from '../../compoments/disease/CommentPosts';
import DiseaseAuthor from '../../compoments/disease/DiseaseAuthor';
import VideoPopup from '../../modals/VideoPopup';
import { SH_DOCTOR } from '../../utils/constant';
import RespiratoryAllergy from './respiratoryallergy';

const disease_details_data: RespiratoryAllergy = {
    time: "September 15, 2023",
    post_writer: SH_DOCTOR.DR_ARVIND,
    comments: 24,
    about: {
        title: "About Respiratory Allergy",
        description_1: "Homeopathy is a holistic system of medicine that aims to stimulate the body's own healing mechanisms. The body acts against a certain substance: the allergen. The most common allergens in respiratory allergies are pollen, dust, mould, and hair or animal dander. Respiratory allergies will often be diagnosed early in childhood by a paediatrician or allergy specialist. Homeopathy treats the root cause of the ailment, promoting long-term well-being.",
        description_2: "Homeopathic remedies are highly diluted substances, often prepared through a process called potentization. This ensures that the remedies are safe and gentle, with minimal risk of side effects.",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    impact: {
        title: "Respiratory Allergy Impact",
        description_1: "Respiratory allergies affect the respiratory system. Allergic asthma and allergic rhinitis are the two types of respiratory allergies. Allergic rhinitis (also called hay fever) results in nasal symptoms and itchy, watery eyes, while allergic asthma results in airway constriction.",
        description_2: "",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    symptoms: {
        title: "Symptoms",
        description_1: "Respiratory allergies affect the respiratory system. Allergic asthma and allergic rhinitis are the two types of respiratory allergies. Allergic rhinitis (also called hay fever) results in nasal symptoms and itchy, watery eyes, while allergic asthma results in airway constriction.",
        description_2: "Respiratory allergies, as the name suggests, impact the airways and nasal passages of the respiratory system.",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: ["Itchy nose, mouth, throat, and eyes",
            "Sneezing",
            "Congestion",
            "Watery eyes"],
        list_2: [],
        list_3: []
    },
    cause: {
        title: "Cause",
        description_1: "With a respiratory allergy, an allergen causes your symptoms. Like hay fever, some allergens are present seasonally, while others are present year-round.",
        description_2: "Allergens that may trigger respiratory allergies include:",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [
            "Pollen",
            "Pet dander",
            "Dust",
            "Mold and mildew",
            "Latex",

        ],
        list_2: [],
        list_3: []
    },
    treatment: {
        title: "Treatment",
        description_1: "Homeopathy is renowned for its mild and safe approaches. Homeopathy is preferred by people all over the world over conventional medication because it is non-toxic, non-addictive, and has no side effects. Through your immune system and nervous system, homeopathic medicine works by promoting your body's innate ability to heal. It has a reputation for treating a wide range of acute and chronic illnesses including Asthma. All age groups, from young children to the elderly, can use it. As long as they are used under a qualified homeopathic doctor's prescription.",
        description_2: "The primary treatment for respiratory allergies is to avoid allergens that trigger your symptoms. If you’re unsure what your allergens are, allergy testing is valuable to establish what you need to avoid.",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    qoute_text: "A substance that can artificially produce certain disease-like symptoms on a healthy person; only that substance can cure a similar disease when it is given to the patient in the form of homeopathic medicine.",
    qoute_writer: "Homeopathy Principle",
    comments_data: [
        {
            id: 1,
            img: 'comments1.png',
            name: "Karon Balina",
            time: "19th May 2023",
            comment_text: "It's nice Shivam hospital in Mau. ",

        },
        {
            id: 2,
            img: 'comments2.png',
            name: "Julias Roy",
            time: "19th May 2023",
            comment_text: "yap, Its very good nature of all doctors & staff.",

        },
        {
            id: 3,
            img: 'comments1.png',
            name: "Arista Williamson",
            time: "19th May 2023",
            comment_text: "Nice hospital for Homeopath",

        },

    ]

}

const RespiratoryAllergyInnerArea = () => {
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    return (
        <>
            <div className="col">
                <article className="postbox post format-image mb-40">
                    <div className="postbox__text bg-none">

                        <h3 className="blog-title">{disease_details_data.about.title}</h3>
                        <div className="post-text mb-20">
                            <p>{disease_details_data.about.description_1}</p>
                            {/* <p>{disease_details_data.description_2}</p> */}
                            <blockquote>
                                <p>{disease_details_data.qoute_text}</p>
                                <footer>- {disease_details_data.qoute_writer}</footer>
                            </blockquote>
                            <h3 className="blog-title">{disease_details_data.impact.title}</h3>
                            <p>{disease_details_data.impact.description_1}</p>

                            <div className="blog-inner-img mb-30 mt-30">
                                <img src={blog_img} alt="blog" />
                            </div>

                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.symptoms.title}</h3>
                                <p>{disease_details_data.symptoms.description_1}</p>
                                <p>{disease_details_data.symptoms.description_2}</p>
                                <ul className="professinals-list pt-10 pb-20">
                                    {disease_details_data.symptoms.list_1.map((item, i) =>
                                        <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                                    )}
                                </ul>
                                <p>{disease_details_data.symptoms.description_3}</p>
                            </div>
                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.cause.title}</h3>
                                <p>{disease_details_data.cause.description_1}</p>
                                <ul className="professinals-list pt-10 pb-20">
                                    {disease_details_data.cause.list_1.map((item, i) =>
                                        <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                                    )}
                                </ul>
                            </div>
                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.treatment.title}</h3>
                                <p>{disease_details_data.treatment.description_1}</p>
                                <p>{disease_details_data.treatment.description_2}</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="navigation-border pt-50 mt-40"></div>
                            </div>
                        </div>
                    </div>
                    <DiseaseAuthor avatarImg={'namrata2.jpg'} doctorName={SH_DOCTOR.DR_NAMRATA} diseaseCauseDesc={disease_details_data.cause.description_1} />

                    <CommentPosts comment_data={disease_details_data.comments_data} />


                </article>
            </div>

            {/* video modal start */}
            <VideoPopup
                isVideoOpen={isVideoOpen}
                setIsVideoOpen={setIsVideoOpen}
                videoId={'UBdUpt6oTOE'}
            />
            {/* video modal end */}

        </>
    );
};

export default RespiratoryAllergyInnerArea;
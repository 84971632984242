
import imgUrlObesity from '../../assets/img/disease/obesity.jpeg';
import Breadcrumb from '../../compoments/common/Breadcrumb';
import Wrapper from '../../layout/Wrapper';
import FooterThree from '../../layout/footers/FooterThree';
import HeaderFive from '../../layout/headers/HeaderFive';
import DustAllergyInnerArea from './ObesityInnerArea';

const ObesityPage = () => {
  return (
    <Wrapper>
      <HeaderFive />
      <main>
        <Breadcrumb sub_title='Your Heaven for Holistic Healing.' title='Discover the healing power of homeopathy' page='Obesity ' imgUrl={imgUrlObesity} />
        {/* <BlogArea /> */}
        <section className="blog-area pt-120 pb-80">
          <div className="container">
            <div className="row">
              <DustAllergyInnerArea />
            </div>
          </div>
        </section>
      </main>
      <FooterThree />
    </Wrapper>
  );
};

export default ObesityPage;

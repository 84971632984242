import { useRef, useState } from 'react';

import blog_img_thumb from "../../assets/img/blog/b2.jpg";
import video_thumb from '../../assets/img/blog/b3.jpg';
import blog_img from "../../assets/img/blog/b6.jpg";
import left_side_img from '../../assets/img/blog/b9.jpg';
import comment_avatar_1 from '../../assets/img/blog/details/comments1.png';
import comment_avatar_2 from '../../assets/img/blog/details/comments2.png';


import Slider from "react-slick";

import { Link, useNavigate } from 'react-router-dom';
import slider_img_2 from "../../assets/img/blog/b5.jpg";
import slider_img_3 from "../../assets/img/blog/b6.jpg";
import slider_img_1 from "../../assets/img/blog/b7.jpg";
import DiseaseAuthor from '../../compoments/disease/DiseaseAuthor';
import Disease from '../../compoments/disease/disease';
import CommentsForm from '../../compoments/forms/CommentsForm';
import VideoPopup from '../../modals/VideoPopup';
import { APP_ROUTES, SH_DOCTOR } from '../../utils/constant';
const slider_images = [slider_img_1, slider_img_2, slider_img_3]

const setting = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }

    }

  ]
}

const disease_details_data: Disease = {
  time: "September 15, 2023",
  post_writer: SH_DOCTOR.DR_ARVIND,
  comments: 24,
  overview: {
    title: "Depression Overview",
    description_1: "Depression is a chronic lung disease affecting people of all ages. It is caused by inflammation and muscle tightening around the airways, which makes it harder to breathe. Symptoms can include coughing, wheezing, shortness of breath and chest tightness.These symptoms can be mild or severe and can come and go over time. Although depression can be a serious condition, it can be managed with the right treatment.People with symptoms of depression should speak to a health professional.",
    description_2: "",
    description_3: "",
    description_4: "",
    description_5: "",
    description_6: "",
    list_1: [],
    list_2: [],
    list_3: []
  },
  impact: {
    title: "Depression Impact",
    description_1: "Depression is often under-diagnosed and under-treated, particularly in low- and middle-income countries. People with under - treated depression can suffer sleep disturbance, tiredness during the day, and poor concentration.Depression sufferers and their families may miss school and work, with financial impact on the family and wider community.If symptoms are severe, people with depression may need to receive emergency health care and they may be admitted to hospital for treatment and monitoring.In the most severe cases, depression can lead to death.",
    description_2: "",
    description_3: "",
    description_4: "",
    description_5: "",
    description_6: "",
    list_1: [],
    list_2: [],
    list_3: []
  },
  symptoms: {
    title: "Symptoms",
    description_1: "Symptoms of depression can vary from person to person. Symptoms sometimes get significantly worse. This is known as an depression attack. Symptoms are often worse at night or during exercise.",
    description_2: "Common symptoms of depression include:",
    description_3: "Some people will have worse symptoms when they have a cold or during changes in the weather. Other triggers can include dust, smoke, fumes, grass and tree pollen, animal fur and feathers, strong soaps and perfume. Symptoms can be caused by other conditions as well.People with symptoms should talk to a healthcare provider.",
    description_4: "",
    description_5: "",
    description_6: "",
    list_1: ["a persistent cough, especially at night",
      "wheezing when exhaling and sometimes when inhaling",
      "shortness of breath or difficulty breathing, sometimes even when resting",
      "chest tightness, making it difficult to breathe deeply."],
    list_2: [],
    list_3: []
  },
  cause: {
    title: "Cause",
    description_1: "Many factors have been linked to an increased risk of developing depression, although it is often difficult to find a single, direct cause.",
    description_2: "",
    description_3: "",
    description_4: "",
    description_5: "",
    description_6: "",
    list_1: [
      "Depression is more likely if other family members also have depression - particularly a close relative, such as a parent or sibling.",
      "Depression is more likely in people who have other allergic conditions, such as eczema and rhinitis (hay fever).",
      "Urbanization is associated with increased depression prevalence, probably due to multiple lifestyle factors.",
      "Events in early life affect the developing lungs and can increase the risk of depression. These include low birth weight, prematurity, exposure to tobacco smoke and other sources of air pollution, as well as viral respiratory infections.",
      "Exposure to a range of environmental allergens and irritants are also thought to increase the risk of depression, including indoor and outdoor air pollution, house dust mites, moulds, and occupational exposure to chemicals, fumes or dust.",
      "Children and adults who are overweight or obese are at a greater risk of depression."
    ],
    list_2: [],
    list_3: []
  },
  treatment: {
    title: "Treatment",
    description_1: "Homeopathy is renowned for its mild and safe approaches. Homeopathy is preferred by people all over the world over conventional medication because it is non-toxic, non-addictive, and has no side effects. Through your immune system and nervous system, homeopathic medicine works by promoting your body's innate ability to heal. It has a reputation for treating a wide range of acute and chronic illnesses including Depression. All age groups, from young children to the elderly, can use it. As long as they are used under a qualified homeopathic doctor's prescription.",
    description_2: "Homeopathic depression treatment treats the root cause of the condition, it is natural, safe, and effective. Adding lifestyle modifications, exercise, and a balanced diet to homeopathic depression treatment can help improve lung function and lessen symptoms of the condition.",
    description_3: "",
    description_4: "",
    description_5: "",
    description_6: "",
    list_1: [],
    list_2: [],
    list_3: []
  },
  qoute_text: "Between a third and one half of children, adolescents and adults with depression symptoms have severe symptoms that regularly interfere with everyday life.",
  qoute_writer: "Global Depression Report",
  comments_data: [
    {
      id: 1,
      img: comment_avatar_1,
      name: "Karon Balina",
      time: "19th May 2023",
      comment_text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

    },
    {
      id: 2,
      img: comment_avatar_2,
      name: "Julias Roy",
      time: "19th May 2023",
      comment_text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

    },
    {
      id: 3,
      img: comment_avatar_1,
      name: "Arista Williamson",
      time: "19th May 2023",
      comment_text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

    },

  ]

}

const DepressionDetailsInnerArea = ({ style_details, style_left, style_audio, style_video, style_gallery }: any) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const navigate = useNavigate();

  const sliderRef = useRef<Slider | null>(null);
  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }
  return (
    <>
      <div className="col-lg-8">
        <article className="postbox post format-image mb-40">
          {style_details &&
            <div className="postbox__thumb mb-35">
              <img src={blog_img_thumb} alt="blog" />
            </div>

          }
          {style_left &&
            <div className="postbox__thumb mb-35">
              <img src={left_side_img} alt="blog" />
            </div>
          }
          {style_audio &&
            <div className="postbox__audio embed-responsive embed-responsive-16by9 mb-35">
              <iframe title='sound' style={{ width: '100%', height: "500px" }}
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/469608615&color=%23ff0000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
            </div>
          }
          {style_video &&
            <div className="postbox__video mb-35">
              <img src={video_thumb} alt="blog" />
              <Link to={""} className="popup-video video-btn"
                onClick={() => setIsVideoOpen(true)}
                style={{ cursor: "pointer" }}
              ><i className="fas fa-play"></i></Link>
            </div>
          }
          {style_gallery &&
            <div className="postbox__gallery mb-35 position-relative">
              <button type="button" className="slick-prev slick-arrow" onClick={handlePrev}>
                <i className="fas fa-arrow-left"></i>
              </button>
              <button type="button" className="slick-next slick-arrow" onClick={handleNext}>
                <i className="fas fa-arrow-right"></i>
              </button>
              <Slider {...setting} ref={sliderRef}>
                {slider_images.map((item, i) =>
                  <img src={item} key={i} style={{ width: '100%', height: 'auto' }} alt="portfolio" />
                )}
              </Slider>
            </div>
          }



          <div className="postbox__text bg-none">
            <div className="post-meta mb-15">
              <span><i className="far fa-calendar-check"></i> {disease_details_data.time} </span>
              <span role='button' onClick={() => { navigate(APP_ROUTES.DR_ARVIND) }}><i className="far fa-user"></i>{disease_details_data.post_writer}</span>
              <span role='button' onClick={() => { navigate(APP_ROUTES.DR_ARVIND) }}><i className="far fa-comments"></i> {disease_details_data.comments} Comments</span>
            </div>
            <h3 className="blog-title">{disease_details_data.overview.title}</h3>
            <div className="post-text mb-20">
              <p>{disease_details_data.overview.description_1}</p>
              {/* <p>{disease_details_data.description_2}</p> */}
              <blockquote>
                <p>{disease_details_data.qoute_text}</p>
                <footer>- {disease_details_data.qoute_writer}</footer>
              </blockquote>
              <h3 className="blog-title">{disease_details_data.impact.title}</h3>
              <p>{disease_details_data.impact.description_1}</p>

              <div className="blog-inner-img mb-30 mt-30">
                <img src={blog_img} alt="blog " />
              </div>

              <div className="inner-content">
                <h3 className="blog-title">{disease_details_data.symptoms.title}</h3>
                <p>{disease_details_data.symptoms.description_1}</p>
                <p>{disease_details_data.symptoms.description_2}</p>
                <ul className="professinals-list pt-10 pb-20">
                  {disease_details_data.symptoms.list_1.map((item, i) =>
                    <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                  )}
                </ul>
                <p>{disease_details_data.symptoms.description_3}</p>
              </div>
              <div className="inner-content">
                <h3 className="blog-title">{disease_details_data.cause.title}</h3>
                <p>{disease_details_data.cause.description_1}</p>
                <ul className="professinals-list pt-10 pb-20">
                  {disease_details_data.cause.list_1.map((item, i) =>
                    <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                  )}
                </ul>
              </div>
              <div className="inner-content">
                <h3 className="blog-title">{disease_details_data.treatment.title}</h3>
                <p>{disease_details_data.treatment.description_1}</p>
                <p>{disease_details_data.treatment.description_2}</p>

              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="navigation-border pt-50 mt-40"></div>
              </div>
            </div>
          </div>
          <DiseaseAuthor avatarImg={'namrata2.jpg'} doctorName={SH_DOCTOR.DR_NAMRATA} diseaseCauseDesc={disease_details_data.cause.description_1} />

          <div className="post-comments">
            <div className="blog-coment-title mb-30">
              <h2>03 Comments</h2>
            </div>
            <div className="latest-comments">
              <ul>
                {disease_details_data.comments_data.map((comment: any, index: number) =>
                  <li key={index} className={index === 1 ? "children" : ""}>
                    <div className="comments-box">
                      <div className="comments-avatar">
                        <img src={comment.img} alt="title" />
                      </div>
                      <div className="comments-text">
                        <div className="avatar-name">
                          <h5>{comment.name}</h5>
                          <span>{comment.time}</span>
                          <Link className="reply" to="#"><i className="fas fa-reply"></i>Reply</Link>
                        </div>
                        <p>{comment.comment_text}</p>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="post-comments-form">
            <div className="post-comments-title">
              <h2>Post Comments</h2>
            </div>
            <CommentsForm />
          </div>
        </article>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={'UBdUpt6oTOE'}
      />
      {/* video modal end */}

    </>
  );
};

export default DepressionDetailsInnerArea;
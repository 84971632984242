
import { useEffect } from 'react';
import DoctorDetails from '../compoments/doctors/Doctordetails';
import { doctor_detail_data, doctor_details_content } from '../data/DoctorDetailData';
import Wrapper from '../layout/Wrapper';
import { PAGE_TITLE } from '../utils/constant';



const ArvindSrivastav = () => {

  useEffect(() => {
    // Set the page title when the component mounts
    document.title = PAGE_TITLE.DR_ARVIND;

    // Optionally, you can return a cleanup function to reset the title when the component unmounts
    return () => {
      document.title = PAGE_TITLE.HOME;
    };
  }, []);

  return (
    <Wrapper>
      <DoctorDetails doctorDetail={doctor_detail_data.arvind} doctorDetailContent={doctor_details_content.arvind} />
    </Wrapper>
  );
};

export default ArvindSrivastav;
import { useState } from 'react';
import blog_img from "../../assets/img/blog/b6.jpg";
import comment_avatar_1 from '../../assets/img/blog/details/comments1.png';
import comment_avatar_2 from '../../assets/img/blog/details/comments2.png';
import DiseaseAuthor from '../../compoments/disease/DiseaseAuthor';
import CommentsForm from '../../compoments/forms/CommentsForm';
import VideoPopup from '../../modals/VideoPopup';
import { SH_DOCTOR } from '../../utils/constant';
import HairLoss from './hairloss';

const disease_details_data: HairLoss = {
    time: "September 15, 2023",
    post_writer: SH_DOCTOR.DR_ARVIND,
    comments: 24,
    about: {
        title: "About Hair Loss",
        description_1: "Homeopathy is a holistic system of medicine that aims to stimulate the body's own healing mechanisms. It is based on the principle of \"like cures like,\" where a substance that causes symptoms in a healthy person can be used in a highly diluted form to treat similar symptoms in an individual. Homeopathy treats the root cause of the ailment, promoting long-term well-being.",
        description_2: "Homeopathic remedies are highly diluted substances, often prepared through a process called potentization. This ensures that the remedies are safe and gentle, with minimal risk of side effects.",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    impact: {
        title: "Hair loss Impact",
        description_1: "Asthma is often under-diagnosed and under-treated, particularly in low- and middle-income countries. People with under - treated asthma can suffer sleep disturbance, tiredness during the day, and poor concentration.Asthma sufferers and their families may miss school and work, with financial impact on the family and wider community.If symptoms are severe, people with asthma may need to receive emergency health care and they may be admitted to hospital for treatment and monitoring.In the most severe cases, asthma can lead to death.",
        description_2: "",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    symptoms: {
        title: "Symptoms",
        description_1: "Symptoms of asthma can vary from person to person. Symptoms sometimes get significantly worse. This is known as an asthma attack. Symptoms are often worse at night or during exercise.",
        description_2: "Common symptoms of asthma include:",
        description_3: "Some people will have worse symptoms when they have a cold or during changes in the weather. Other triggers can include dust, smoke, fumes, grass and tree pollen, animal fur and feathers, strong soaps and perfume. Symptoms can be caused by other conditions as well.People with symptoms should talk to a healthcare provider.",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: ["a persistent cough, especially at night",
            "wheezing when exhaling and sometimes when inhaling",
            "shortness of breath or difficulty breathing, sometimes even when resting",
            "chest tightness, making it difficult to breathe deeply."],
        list_2: [],
        list_3: []
    },
    cause: {
        title: "Cause",
        description_1: "Many factors have been linked to an increased risk of developing asthma, although it is often difficult to find a single, direct cause.",
        description_2: "",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [
            "Asthma is more likely if other family members also have asthma - particularly a close relative, such as a parent or sibling.",
            "Asthma is more likely in people who have other allergic conditions, such as eczema and rhinitis (hay fever).",
            "Urbanization is associated with increased asthma prevalence, probably due to multiple lifestyle factors.",
            "Events in early life affect the developing lungs and can increase the risk of asthma. These include low birth weight, prematurity, exposure to tobacco smoke and other sources of air pollution, as well as viral respiratory infections.",
            "Exposure to a range of environmental allergens and irritants are also thought to increase the risk of asthma, including indoor and outdoor air pollution, house dust mites, moulds, and occupational exposure to chemicals, fumes or dust.",
            "Children and adults who are overweight or obese are at a greater risk of asthma."
        ],
        list_2: [],
        list_3: []
    },
    treatment: {
        title: "Treatment",
        description_1: "Homeopathy is renowned for its mild and safe approaches. Homeopathy is preferred by people all over the world over conventional medication because it is non-toxic, non-addictive, and has no side effects. Through your immune system and nervous system, homeopathic medicine works by promoting your body's innate ability to heal. It has a reputation for treating a wide range of acute and chronic illnesses including Asthma. All age groups, from young children to the elderly, can use it. As long as they are used under a qualified homeopathic doctor's prescription.",
        description_2: "Homeopathic asthma treatment treats the root cause of the condition, it is natural, safe, and effective. Adding lifestyle modifications, exercise, and a balanced diet to homeopathic asthma treatment can help improve lung function and lessen symptoms of the condition.",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    qoute_text: "A substance that can artificially produce certain disease-like symptoms on a healthy person; only that substance can cure a similar disease when it is given to the patient in the form of homeopathic medicine.",
    qoute_writer: "Homeopathy Principle",
    comments_data: [
        {
            id: 1,
            img: comment_avatar_1,
            name: "Karon Balina",
            time: "19th May 2023",
            comment_text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

        },
        {
            id: 2,
            img: comment_avatar_2,
            name: "Julias Roy",
            time: "19th May 2023",
            comment_text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

        },
        {
            id: 3,
            img: comment_avatar_1,
            name: "Arista Williamson",
            time: "19th May 2023",
            comment_text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

        },

    ]

}

const HairLossInnerArea = () => {
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    return (
        <>
            <div className="col">
                <article className="postbox post format-image mb-40">
                    <div className="postbox__text bg-none">

                        <h3 className="blog-title">{disease_details_data.about.title}</h3>
                        <div className="post-text mb-20">
                            <p>{disease_details_data.about.description_1}</p>
                            {/* <p>{disease_details_data.description_2}</p> */}
                            <blockquote>
                                <p>{disease_details_data.qoute_text}</p>
                                <footer>- {disease_details_data.qoute_writer}</footer>
                            </blockquote>
                            <h3 className="blog-title">{disease_details_data.impact.title}</h3>
                            <p>{disease_details_data.impact.description_1}</p>

                            <div className="blog-inner-img mb-30 mt-30">
                                <img src={blog_img} alt="blog" />
                            </div>

                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.symptoms.title}</h3>
                                <p>{disease_details_data.symptoms.description_1}</p>
                                <p>{disease_details_data.symptoms.description_2}</p>
                                <ul className="professinals-list pt-10 pb-20">
                                    {disease_details_data.symptoms.list_1.map((item, i) =>
                                        <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                                    )}
                                </ul>
                                <p>{disease_details_data.symptoms.description_3}</p>
                            </div>
                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.cause.title}</h3>
                                <p>{disease_details_data.cause.description_1}</p>
                                <ul className="professinals-list pt-10 pb-20">
                                    {disease_details_data.cause.list_1.map((item, i) =>
                                        <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                                    )}
                                </ul>
                            </div>
                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.treatment.title}</h3>
                                <p>{disease_details_data.treatment.description_1}</p>
                                <p>{disease_details_data.treatment.description_2}</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="navigation-border pt-50 mt-40"></div>
                            </div>
                        </div>
                    </div>
                    <DiseaseAuthor avatarImg={'namrata2.jpg'} doctorName={SH_DOCTOR.DR_NAMRATA} diseaseCauseDesc={disease_details_data.cause.description_1} />

                    <div className="post-comments">
                        <div className="blog-coment-title mb-30">
                            <h2>03 Comments</h2>
                        </div>
                        <div className="latest-comments">
                            <ul>
                                {disease_details_data.comments_data.map((comment: any, index: number) =>
                                    <li key={index} className={index === 1 ? "children" : ""}>
                                        <div className="comments-box">
                                            <div className="comments-avatar">
                                                <img src={comment.img} alt="title" />
                                            </div>
                                            <div className="comments-text">
                                                <div className="avatar-name">
                                                    <h5>{comment.name}</h5>
                                                    <span>{comment.time}</span>
                                                    <div role="button" className="reply" ><i className="fas fa-reply"></i>Reply</div>
                                                </div>
                                                <p>{comment.comment_text}</p>
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="post-comments-form">
                        <div className="post-comments-title">
                            <h2>Post Comments</h2>
                        </div>
                        <CommentsForm />
                    </div>
                </article>
            </div>

            {/* video modal start */}
            <VideoPopup
                isVideoOpen={isVideoOpen}
                setIsVideoOpen={setIsVideoOpen}
                videoId={'UBdUpt6oTOE'}
            />
            {/* video modal end */}

        </>
    );
};

export default HairLossInnerArea;
import { useState } from 'react';
import blog_img from "../../assets/img/blog/b6.jpg";
import CommentPosts from '../../compoments/disease/CommentPosts';
import DiseaseAuthor from '../../compoments/disease/DiseaseAuthor';
import VideoPopup from '../../modals/VideoPopup';
import { SH_DOCTOR } from '../../utils/constant';
import Autism from './autism';

const disease_details_data: Autism = {
    time: "September 15, 2023",
    post_writer: SH_DOCTOR.DR_ARVIND,
    comments: 24,
    about: {
        title: "About Autism",
        description_1: "Homeopathy is a holistic system of medicine that aims to stimulate the body's own healing mechanisms. Home is supposed to be a comforting oasis, but for people with Autism the home can trigger uncomfortable symptoms. Oddly enough, allergy symptoms often worsen during or immediately after vacuuming, sweeping and dusting. The process of cleaning can stir up dust particles, making them easier to inhale. Homeopathy treats the root cause of the ailment, promoting long-term well-being.",
        description_2: "Homeopathic remedies are highly diluted substances, often prepared through a process called potentization. This ensures that the remedies are safe and gentle, with minimal risk of side effects.",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    impact: {
        title: "Autism Impact",
        description_1: "Home is supposed to be a comforting oasis, but for people with Autism the home can trigger uncomfortable symptoms. Oddly enough, allergy symptoms often worsen during or immediately after vacuuming, sweeping and dusting. The process of cleaning can stir up dust particles, making them easier to inhale.",
        description_2: "People with Autism often suffer the most inside their homes or in other people’s homes. Dust mites are tiny organisms that can barely be seen by the naked eye. They feed off house dust and the moisture in the air. They are one of the most common indoor allergens, and symptoms can be present year-round. In addition to allergic rhinitis, dust mite allergy can also trigger asthma and cause eczema to flare.",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    symptoms: {
        title: "Symptoms",
        description_1: "Symptoms sometimes get significantly worse. Symptoms are often worse at day or during construction.",
        description_2: "Common symptoms of dust include:",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: ["Sneezing",
            "Runny or stuffy nose",
            "Red, itchy or teary eyes",
            "Wheezing, coughing, tightness in the chest and shortness of breath",
            "Itching"],
        list_2: [],
        list_3: []
    },
    cause: {
        title: "Cause",
        description_1: "Many factors have been linked to an increased risk of developing dust allergy, although it is often difficult to find a single, direct cause.",
        description_2: "",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [
            "Dust mites",
            "Cockroaches",
            "Mold",
            "Pollen",
            "Pet hair ",
            "fur or feathers"
        ],
        list_2: [],
        list_3: []
    },
    treatment: {
        title: "Treatment",
        description_1: "Homeopathy is renowned for its mild and safe approaches. Homeopathy is preferred by people all over the world over conventional medication because it is non-toxic, non-addictive, and has no side effects. Through your immune system and nervous system, homeopathic medicine works by promoting your body's innate ability to heal. It has a reputation for treating a wide range of acute and chronic illnesses including Asthma. All age groups, from young children to the elderly, can use it. As long as they are used under a qualified homeopathic doctor's prescription.",
        description_2: "Homeopathic dust allergy treatment treats the root cause of the condition, it is natural, safe, and effective. Adding lifestyle modifications, exercise, and a balanced diet to homeopathic asthma treatment can help improve lung function and lessen symptoms of the condition.",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    qoute_text: "A substance that can artificially produce certain disease-like symptoms on a healthy person; only that substance can cure a similar disease when it is given to the patient in the form of homeopathic medicine.",
    qoute_writer: "Homeopathy Principle",
    comments_data: [
        {
            id: 1,
            img: 'comments1.png',
            name: "Karan",
            time: "19th May 2023",
            comment_text: "It's best hospital in Mau.",

        },
        {
            id: 2,
            img: 'comments2.png',
            name: "Julias Rai",
            time: "19th May 2023",
            comment_text: "it's better hopsital in the Mau.",

        },
        {
            id: 3,
            img: 'comments1.png',
            name: "Arista Williamson",
            time: "19th May 2023",
            comment_text: "Nice hospital",

        },

    ]

}

const DustAllergyInnerArea = () => {
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    return (
        <>
            <div className="col">
                <article className="postbox post format-image mb-40">
                    <div className="postbox__text bg-none">

                        <h3 className="blog-title">{disease_details_data.about.title}</h3>
                        <div className="post-text mb-20">
                            <p>{disease_details_data.about.description_1}</p>
                            {/* <p>{disease_details_data.description_2}</p> */}
                            <blockquote>
                                <p>{disease_details_data.qoute_text}</p>
                                <footer>- {disease_details_data.qoute_writer}</footer>
                            </blockquote>
                            <h3 className="blog-title">{disease_details_data.impact.title}</h3>
                            <p>{disease_details_data.impact.description_1}</p>

                            <div className="blog-inner-img mb-30 mt-30">
                                <img src={blog_img} alt="blog" />
                            </div>

                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.symptoms.title}</h3>
                                <p>{disease_details_data.symptoms.description_1}</p>
                                <p>{disease_details_data.symptoms.description_2}</p>
                                <ul className="professinals-list pt-10 pb-20">
                                    {disease_details_data.symptoms.list_1.map((item, i) =>
                                        <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                                    )}
                                </ul>
                                <p>{disease_details_data.symptoms.description_3}</p>
                            </div>
                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.cause.title}</h3>
                                <p>{disease_details_data.cause.description_1}</p>
                                <ul className="professinals-list pt-10 pb-20">
                                    {disease_details_data.cause.list_1.map((item, i) =>
                                        <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                                    )}
                                </ul>
                            </div>
                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.treatment.title}</h3>
                                <p>{disease_details_data.treatment.description_1}</p>
                                <p>{disease_details_data.treatment.description_2}</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="navigation-border pt-50 mt-40"></div>
                            </div>
                        </div>
                    </div>

                    <DiseaseAuthor avatarImg={'namrata2.jpg'} doctorName={SH_DOCTOR.DR_NAMRATA} diseaseCauseDesc={disease_details_data.cause.description_1} />

                    <CommentPosts comment_data={disease_details_data.comments_data} />

                </article>
            </div>

            {/* video modal start */}
            <VideoPopup
                isVideoOpen={isVideoOpen}
                setIsVideoOpen={setIsVideoOpen}
                videoId={'UBdUpt6oTOE'}
            />
            {/* video modal end */}

        </>
    );
};

export default DustAllergyInnerArea;

import Breadcrumb from '../../compoments/common/Breadcrumb';
import DiseaseSidebarArea from '../../compoments/disease/DiseaseSidebarArea';
import Wrapper from '../../layout/Wrapper';
import FooterThree from '../../layout/footers/FooterThree';
import HeaderFive from '../../layout/headers/HeaderFive';
import AsthmaDetailsInnerArea from './AsthmaDetailsInnerArea';

import imgUrl from '../../assets/img/disease/Lung-image-with-doctor.jpg';



const AsthmaPage = () => {
  return (
    <>
      <Wrapper>
        <HeaderFive />
        <main>
          <Breadcrumb sub_title='We are here for your care.' title='Breath Seamlessly' page='Asthma' imgUrl={imgUrl} />
          {/* <BlogArea /> */}
          <section className="blog-area pt-120 pb-80">
            <div className="container">
              <div className="row">
                <AsthmaDetailsInnerArea style_details={true} />
                <DiseaseSidebarArea />
              </div>
            </div>
          </section>
        </main>
        <FooterThree />
      </Wrapper>
    </>
  );
};

export default AsthmaPage;


import team_avatar_1 from "../assets/img/team/member1.jpg";
import team_avatar_2 from "../assets/img/team/member2.jpg";
import team_avatar_3 from "../assets/img/team/member3.jpeg";

import arvindImgUrl from "../assets/img/doctor/drarvind.jpg";
import deepmalaImgUrl from "../assets/img/doctor/drdeepmala.jpeg";
import namrataImgUrl from "../assets/img/doctor/drnamrata.jpg";

import { APP_ROUTES, DR_QUALIFICATION, SH_DOCTOR } from "../utils/constant";



interface TeamDataType {
  id: number;
  home?: number;
  img: string;
  name: string;
  job_title: string;
  url: string;
}

const team_data: TeamDataType[] = [
  {
    id: 1,
    img: team_avatar_1,
    name: SH_DOCTOR.DR_ARVIND,
    job_title: DR_QUALIFICATION.ARVIND_1,
    url: APP_ROUTES.DR_ARVIND
  },
  {
    id: 2,
    img: team_avatar_2,
    name: SH_DOCTOR.DR_NAMRATA,
    job_title: DR_QUALIFICATION.NAMRATA_1,
    url: APP_ROUTES.DR_NAMRATA
  },
  {
    id: 3,
    img: team_avatar_3,
    name: SH_DOCTOR.DR_DEEPMALA,
    job_title: DR_QUALIFICATION.DEEPMALA_1,
    url: APP_ROUTES.DR_DEEPMALA
  },
  {
    id: 1,
    img: arvindImgUrl,
    name: SH_DOCTOR.DR_ARVIND,
    job_title: DR_QUALIFICATION.ARVIND_1,
    url: APP_ROUTES.DR_ARVIND
  },
  {
    id: 2,
    img: namrataImgUrl,
    name: SH_DOCTOR.DR_NAMRATA,
    job_title: DR_QUALIFICATION.NAMRATA_1,
    url: APP_ROUTES.DR_NAMRATA
  },
  {
    id: 3,
    img: deepmalaImgUrl,
    name: SH_DOCTOR.DR_DEEPMALA,
    job_title: DR_QUALIFICATION.DEEPMALA_1,
    url: APP_ROUTES.DR_DEEPMALA
  }

]
export default team_data

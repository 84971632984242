import { useState } from 'react';
import about_img from '../../assets/img/about/about-img.jpg';
import about_shape from "../../assets/img/about/about-shape.png";
import icon_1 from "../../assets/img/about/destination-icon-1.png";
import icon_2 from "../../assets/img/about/destination-icon-2.png";
import VideoPopup from '../../modals/VideoPopup';

interface DataType {
  sub_title: string;
  title: string;
  sm_des: string;
  our_feature: {
    id: number;
    img: string;
    title: string;
    sm_des: string;
  }[];
}

const about_video_content: DataType = {
  sub_title: "About Us",
  title: "Short Story About Shivam Health Care.",
  sm_des: "We provide you the quality homeopathic treatment for all your chronic and long standing illness and expert in diagnosis and treatment of common and rare disease such as skin disease, Warts, Corn, Leukoderma, Eczema, Psoriasis, Cracks, Dermatitis, Diabetes, Hairfall, Cyst, Benign, Malignant tumor, Leucorrhoea, Monthly irregularity, Inflammation of the uterus, Tumour, Ovarian, Childless female and male infertility , Male and female sexual dysfunction, Autoimmune diseases. We provide best treatement in very less expense.",
  our_feature: [
    {
      id: 1,
      img: icon_1,
      title: "Our Mission",
      sm_des: "Is to equip people with knowledge about their personal health, give them better access to health facilities and create awareness about hygienic living along with early detection and prevention, and provide them with the necessary medication to cure their ailments.",
    },
    {
      id: 2,
      img: icon_2,
      title: "Our Vission",
      sm_des: "Is to meet the health needs of the people providing high quality, comprehensive healthcare to all and strive to be the premier health care provider to residents in its service area.",
    },
  ]
}
const { sub_title, title, sm_des, our_feature } = about_video_content

const AboutVideoArea = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  return (
    <>
      <section className="about-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="about-left-side pos-rel mb-30">
                <div className="about-front-img pos-rel">
                  <img src={about_img} alt="theme-pure" />
                  <div role='button' className="popup-video about-video-btn white-video-btn"
                    onClick={() => setIsVideoOpen(true)}
                    style={{ cursor: "pointer" }}
                  ><i className="fas fa-play"></i></div>
                </div>
                <div className="about-shape">
                  <img src={about_shape} alt="theme-pure" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="about-right-side pt-55 mb-30">
                <div className="about-title mb-20">
                  <h5>{sub_title}</h5>
                  <h2>{title}</h2>
                </div>
                <div className="about-text mb-50">
                  <p>{sm_des}</p>
                </div>
                <div className="our-destination">
                  {our_feature.map((item, i) =>
                    <div key={i} className={`single-item ${i === 0 ? "mb-30" : ""}`}>
                      <div className="mv-icon f-left">
                        <img src={item.img} alt="theme-pure" />
                      </div>
                      <div className="mv-title fix">
                        <h3>{item.title}</h3>
                        <p>{item.sm_des}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={'UBdUpt6oTOE'}
      />
      {/* video modal end */}
    </>
  );
};

export default AboutVideoArea;
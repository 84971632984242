
import { useState } from 'react';

import blog_img from "../../assets/img/blog/b6.jpg";


import CommentPosts from '../../compoments/disease/CommentPosts';
import DiseaseAuthor from '../../compoments/disease/DiseaseAuthor';
import VideoPopup from '../../modals/VideoPopup';
import { SH_DOCTOR } from '../../utils/constant';
import FoodAllergy from './foodallergy';

const disease_details_data: FoodAllergy = {
    time: "September 15, 2023",
    post_writer: SH_DOCTOR.DR_ARVIND,
    comments: 24,
    about: {
        title: "About Food Allergy",
        description_1: "Homeopathy is a holistic system of medicine that aims to stimulate the body's own healing mechanisms.A food allergy is an abnormal immune response to food. The symptoms of the allergic reaction may range from mild to severe. They may include itchiness, swelling of the tongue, vomiting, diarrhea, hives, trouble breathing, or low blood pressure. This typically occurs within minutes to several hours of exposure. When the symptoms are severe, it is known as anaphylaxis.",
        description_2: "Homeopathic remedies are highly diluted substances, often prepared through a process called potentization. This ensures that the remedies are safe and gentle, with minimal risk of side effects.",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    impact: {
        title: "Food Allergy Impact",
        description_1: "Food allergy is an immune system reaction that happens soon after eating a certain food. Even a tiny amount of the allergy-causing food can trigger symptoms such as digestive problems, hives or swollen airways. In some people, a food allergy can cause severe symptoms or even a life-threatening reaction known as anaphylaxis. Food allergy affects an estimated 8% of children under age 5 and up to 4% of adults. While there's no cure, some children outgrow their food allergies as they get older. It's easy to confuse a food allergy with a much more common reaction known as food intolerance. While bothersome, food intolerance is a less serious condition that does not involve the immune system.",
        description_2: "",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    symptoms: {
        title: "Symptoms",
        description_1: "Food allergy symptoms usually develop within a few minutes to two hours after eating the offending food. Rarely, symptoms may be delayed for several hours.",
        description_2: "The most common food allergy symptoms include:",
        description_3: "Tingling or itching in the mouth.",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: ["Wheezing, nasal congestion or trouble breathing.",
            "Belly pain, diarrhea, nausea or vomiting.",
            "Dizziness, lightheadedness or fainting.",
            "Swelling of the lips, face, tongue, and throat or other parts of the body."],
        list_2: [],
        list_3: []
    },
    cause: {
        title: "Cause",
        description_1: "When you have a food allergy, your immune system mistakenly identifies a specific food or a substance in food as something harmful. In response, your immune system triggers cells to release an antibody known as immunoglobulin E (IgE) to neutralize the allergy-causing food or food substance, called an allergen.",
        description_2: "Most food allergies are triggered by certain proteins in:",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [
            "Crustacean shellfish, such as shrimp, lobster and crab.",
            "Peanuts.",
            "Tree nuts, such as walnuts and pecans.",
            "Fish.",
            "Chicken eggs.",
            "Cow's milk."
        ],
        list_2: [],
        list_3: []
    },
    treatment: {
        title: "Treatment",
        description_1: "Homeopathic treatment of food allergies can be very beneficial as it treats food allergies by optimising the overcharged immune system. Though the treatment may require a more serious approach, most of the allergies can be treated with homeopathy. Homeopathy views food allergies as an expression of a disturbance in the patient’s healing system. Food allergies are not a disease to be cured in themselves, but a reflection of an underlying imbalance.",
        description_2: "Food sensitivity or intolerance can be treated by homeopathic medicines that are specific to that particular problem. For example, homeopathic medicine Silicea for milk intolerance in infants, when even the mother’s milk disagrees; Zingiber for diarrhea that sets in after eating melons; Antim crudum for headache that comes after eating fruits.",
        description_3: "",
        description_4: "",
        description_5: "",
        description_6: "",
        list_1: [],
        list_2: [],
        list_3: []
    },
    qoute_text: "A substance that can artificially produce certain disease-like symptoms on a healthy person; only that substance can cure a similar disease when it is given to the patient in the form of homeopathic medicine.",
    qoute_writer: "Homeopathy Principle",
    comments_data: [
        {
            id: 1,
            img: 'comments1.png',
            name: "Shyam Singh",
            time: "19th May 2023",
            comment_text: "It's best treatment in Shivam health care hospital",

        },
        {
            id: 2,
            img: 'comments2.png',
            name: "Julias Roy",
            time: "19th May 2023",
            comment_text: "Yes, It's best hospital in Mau Dist.",

        },
        {
            id: 3,
            img: 'comments1.png',
            name: "Arita Roy",
            time: "19th May 2023",
            comment_text: "Good Shivam Hospital.",

        },

    ]

}

const FoodAllergyInnerArea = () => {
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    return (
        <>
            <div className="col">
                <article className="postbox post format-image mb-40">
                    <div className="postbox__text bg-none">

                        <h3 className="blog-title">{disease_details_data.about.title}</h3>
                        <div className="post-text mb-20">
                            <p>{disease_details_data.about.description_1}</p>
                            {/* <p>{disease_details_data.description_2}</p> */}
                            <blockquote>
                                <p>{disease_details_data.qoute_text}</p>
                                <footer>- {disease_details_data.qoute_writer}</footer>
                            </blockquote>
                            <h3 className="blog-title">{disease_details_data.impact.title}</h3>
                            <p>{disease_details_data.impact.description_1}</p>

                            <div className="blog-inner-img mb-30 mt-30">
                                <img src={blog_img} alt="blog" />
                            </div>

                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.symptoms.title}</h3>
                                <p>{disease_details_data.symptoms.description_1}</p>
                                <p>{disease_details_data.symptoms.description_2}</p>
                                <ul className="professinals-list pt-10 pb-20">
                                    {disease_details_data.symptoms.list_1.map((item, i) =>
                                        <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                                    )}
                                </ul>
                                <p>{disease_details_data.symptoms.description_3}</p>
                            </div>
                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.cause.title}</h3>
                                <p>{disease_details_data.cause.description_1}</p>
                                <ul className="professinals-list pt-10 pb-20">
                                    {disease_details_data.cause.list_1.map((item, i) =>
                                        <li key={i}> <i className="fa fa-check" style={{ cursor: "pointer" }}></i> {item} </li>
                                    )}
                                </ul>
                            </div>
                            <div className="inner-content">
                                <h3 className="blog-title">{disease_details_data.treatment.title}</h3>
                                <p>{disease_details_data.treatment.description_1}</p>
                                <p>{disease_details_data.treatment.description_2}</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="navigation-border pt-50 mt-40"></div>
                            </div>
                        </div>
                    </div>

                    <DiseaseAuthor avatarImg={'arvind2.jpg'} doctorName={SH_DOCTOR.DR_ARVIND} diseaseCauseDesc={disease_details_data.cause.description_1} />

                    <CommentPosts comment_data={disease_details_data.comments_data} />


                </article>
            </div>

            {/* video modal start */}
            <VideoPopup
                isVideoOpen={isVideoOpen}
                setIsVideoOpen={setIsVideoOpen}
                videoId={'UBdUpt6oTOE'}
            />
            {/* video modal end */}

        </>
    );
};

export default FoodAllergyInnerArea;

// import service_img_1 from "../assets/img/services/service-icon-1.png";
import service_img_1 from "../assets/img/home/services/service-1.jpg";
import service_img_2 from "../assets/img/services/service-icon-2.png";
import service_img_3 from "../assets/img/services/service-icon-3.png";
import service_img_4 from "../assets/img/services/service-icon-4.png";


import service_img_5_1 from "../assets/img/services/service1.png";
import service_img_5_2 from "../assets/img/services/service2.png";
import service_img_5_3 from "../assets/img/services/service3.png";
import service_img_5_4 from "../assets/img/services/service4.png";
import service_img_5_5 from "../assets/img/services/service5.png";
import service_img_5_6 from "../assets/img/services/service6.png";

import sh_service_1 from "../assets/img/home/services/service-1.jpg";
import sh_service_2 from "../assets/img/home/services/service-2.jpg";
import sh_service_3 from "../assets/img/home/services/service-3.jpg";
import sh_service_4 from "../assets/img/home/services/service-4.jpg";

interface ServciesDataType {
  id: number;
  img: string;
  sub_title?: string;
  title: string;
  sm_des: string;
  totalDoctor?: number;
  service?: boolean;
}

const servcies_data: ServciesDataType[] = [
  {
    id: 1,
    img: service_img_1,
    sub_title: "Older & Children",
    title: "Medical Home Care",
    sm_des: "exercitation lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ullamco laboris nisi ut aliquip.",
  },
  {
    id: 2,
    img: service_img_2,
    sub_title: "Older & Children",
    title: "Medical Eye Point",
    sm_des: "exercitation lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ullamco laboris nisi ut aliquip.",
  },
  {
    id: 3,
    img: service_img_3,
    sub_title: "Older & Children",
    title: "Medical Older Care",
    sm_des: "exercitation lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ullamco laboris nisi ut aliquip.",
  },
  {
    id: 4,
    img: service_img_4,
    sub_title: "Older & Children",
    title: "Medical Dental Care",
    sm_des: "exercitation lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ullamco laboris nisi ut aliquip.",
  },
  // home 04 
  {
    id: 1,
    img: sh_service_1,
    sub_title: "children",
    title: "Children Care",
    totalDoctor: 3,
    sm_des: "Shivam Health Care provides various common childhood ailmemnts such as Teething Troubles, Colds and Coughs, Digestive Upsets, Anxiety and Restlessness, Sleep Disturbances etc.",
  },
  {
    id: 2,
    img: sh_service_2,
    sub_title: "infertility",
    title: "Infertility Treatment",
    totalDoctor: 2,
    sm_des: "We assess your specific situation, conduct necessary tests, and provide appropriate guidance on the most suitable and evidence-based treatments for your individual needs.",
  },
  {
    id: 3,
    img: sh_service_3,
    sub_title: "heart",
    title: "Heart Disease",
    totalDoctor: 6,
    sm_des: "Heart disease is a general term used for pathology related to heart musculature, blood vessels, valves etc. We provide the best homeopathic medicine forHeart disease.",
  },
  {
    id: 4,
    img: sh_service_4,
    sub_title: "gynaecology",
    title: "Women Health",
    totalDoctor: 4,
    sm_des: "Women's health is a complex and dynamic aspect that requires specialized attention. At our homeopathy hospital, we offer a holistic approach to address various health concerns.",
  },
  // service data 
  {
    id: 1,
    img: service_img_5_1,
    service: true,
    title: "Body Surgery",
    sm_des: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
  },
  {
    id: 2,
    img: service_img_5_2,
    service: true,
    title: "Dental Fillings",
    sm_des: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
  },
  {
    id: 3,
    img: service_img_5_3,
    service: true,
    title: "Neurology Sargery",
    sm_des: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
  },
  {
    id: 4,
    img: service_img_5_4,
    service: true,
    title: "Neurology Sargery",
    sm_des: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
  },
  {
    id: 5,
    img: service_img_5_5,
    service: true,
    title: "Neurology Sargery",
    sm_des: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
  },
  {
    id: 6,
    img: service_img_5_6,
    service: true,
    title: "Neurology Sargery",
    sm_des: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
  },


]
export default servcies_data

import React from 'react';
import FooterThree from '../../layout/footers/FooterThree';
import HeaderFive from '../../layout/headers/HeaderFive';
import Payment from './Payment';

const CheckoutPage: React.FC = () => {
  return (
    <>
      <HeaderFive />
      <main>
        <Payment />
      </main>
      <FooterThree />
    </>
  );
};

export default CheckoutPage;
import back_icon from "../../../assets/img/section/section-back-icon.png";
import line_icon from "../../../assets/img/shape/section-title-line.png";
import treatment_data from '../../../data/TreatmentData';


const TreatmentAreaHomeFour = () => {
  return (
    <>
      <section className="team-area pt-180 pb-65">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
              <div className="section-title text-center pos-rel mb-75">
                <div className="section-icon">
                  <img className="section-back-icon" src={back_icon} alt="back-icon" />
                </div>
                <div className="section-text pos-rel">
                  <h5>Shivam Health Care Treatment</h5>
                  <h1>A Professional & Care Provider </h1>
                </div>
                <div className="section-line pos-rel">
                  <img src={line_icon} alt="section-line" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {treatment_data.map((item, i) =>


              <div key={i} className="col-6 col-xl-3 col-lg-4 col-md-6">
                <div className="team-box text-center mb-60">
                  <div className="team-thumb h4team-thumb mb-25 pos-rel">
                    <img src={item.img} alt="" />
                    {/* {i < 9 ?
                      <Link className="team-link" href="/doctor-details">0{item.id}</Link>
                      :
                      <Link className="team-link" href="/doctor-details">{item.id}</Link>

                    } */}
                  </div>
                  <div className="team-content h4team-content mb-15">
                    <h3>{item.name}</h3>
                    {/* <h6>{item.job_title}</h6> */}
                  </div>
                  {/* <div className="h4team-social">
                        <ul className="list-inline">
                          <TeamSocialLinks />
                        </ul>
                      </div> */}
                </div>
              </div>

            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TreatmentAreaHomeFour;